import KeenClient from './KeenClient';

const START_YEAR = 2022;
const START_MONTH = 0;
const START_DAY = 1;
const START_DATE = new Date(
  Date.UTC(START_YEAR, START_MONTH, START_DAY),
).toISOString();
const CURRENT_DATE = new Date().toISOString();
const SUBMITTED_EVENT_TYPE = 'Final Share Screen Viewed';
const DOWNLOAD_EVENT_TYPE = 'Image File Downloaded';
const FACEBOOK_EVENT_TYPE = 'Facebook Share Button Clicked';
const TWITTER_EVENT_TYPE = 'Twitter Share Button Clicked';
const INSTAGRAM_EVENT_TYPE = 'Instagram Share Button Clicked';
const LINKEDIN_EVENT_TYPE = 'LinkedIn Share Button Clicked';
const CONNECTING_KEY = 'hub_collection_url';
const ANALYSIS_TYPE = 'funnel';

export function getAnalytics(url, endDate = CURRENT_DATE) {
  const config = {
    actor_property: 'uuid',
    timeframe: {
      start: START_DATE,
      end: endDate,
    },
    filters: [
      {
        propertyName: CONNECTING_KEY,
        operator: 'eq',
        propertyValue: url,
      },
    ],
  };

  return KeenClient.query({
    analysisType: ANALYSIS_TYPE,
    steps: [
      { ...config, eventCollection: SUBMITTED_EVENT_TYPE },
      { ...config, eventCollection: DOWNLOAD_EVENT_TYPE, optional: true },
      { ...config, eventCollection: FACEBOOK_EVENT_TYPE, optional: true },
      { ...config, eventCollection: TWITTER_EVENT_TYPE, optional: true },
      { ...config, eventCollection: INSTAGRAM_EVENT_TYPE, optional: true },
      { ...config, eventCollection: LINKEDIN_EVENT_TYPE, optional: true },
    ],
  }).catch((error) => {
    console.log(error);
    return error;
  });
}
