import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from 'components/NavBar';
import TopBar from 'components/TopBar';
import ArrowBack from '@material-ui/icons/ArrowBack';
import {
  AlertCircle as AlertCircleIcon,
  Camera as CameraIcon,
  Filter as FilterIcon,
  Grid as GridIcon,
  Layout as LayoutIcon,
  BarChart2 as BarChartIcon,
} from 'react-feather';
import { useParams } from 'react-router';
import EventContext from 'contexts/event';
import { isClientUser } from '../../helpers';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useQuery } from 'react-query';
import axios from 'axios';
import HTTPErrorHandler from '../../components/HTTPErrorHandler';

const EVENT_URL_PATTERN = ':eventId';
const BOOTH_SUBHEADER = 'Booth';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
  },
}));

const EVENT_SECTIONS = [
  {
    subheader: '',
    items: [
      {
        title: 'All Events',
        icon: ArrowBack,
        href: '/events',
      },
    ],
  },
  {
    subheader: 'Event Details',
    items: [
      {
        title: 'Overview',
        icon: LayoutIcon,
        href: '/events/:eventId',
      },
    ],
  },
  {
    subheader: 'Gallery',
    items: [
      {
        title: 'Overview',
        icon: GridIcon,
        href: '/events/:eventId/gallery',
      },
      {
        title: 'Moderation',
        icon: FilterIcon,
        href: '/events/:eventId/moderation',
      },
    ],
  },
  {
    subheader: BOOTH_SUBHEADER,
    items: [
      {
        title: 'Overview',
        icon: CameraIcon,
        href: '/events/:eventId/booth',
      },
    ],
  },
  {
    subheader: 'Analytics',
    items: [
      {
        title: 'Overview',
        icon: BarChartIcon,
        href: '/events/:eventId/analytics',
      },
    ],
  },
  {
    subheader: 'Information',
    items: [
      {
        title: 'Resources',
        icon: AlertCircleIcon,
        href: '/resources',
      },
    ],
  },
];

const generateItemsURLs = (items, searchPattern, replaceValue) => {
  return items.map((item) => {
    const { href, ...rest } = item;
    return {
      ...rest,
      href: href.replace(searchPattern, replaceValue),
    };
  });
};

const EventLayout = ({ children }) => {
  const classes = useStyles();
  const { eventId } = useParams();
  const [sections, setSections] = useState([]);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { oidcUser } = useReactOidc();
  const { event, updateContextValues, fetchAndUpdateCreditsSpent } = useContext(EventContext);

  // Only show "Booth" section in sidebar if user has staff privileges.
  const removeVirtualBoothFromSidebarIfClientUser = (sections) => {
    if (isClientUser(oidcUser)) {
      sections.forEach((section, index) => {
        // If section is the Booth section
        if (section.subheader === BOOTH_SUBHEADER) {
          // Remove it from array so it doesn't show in sidebar for non-staff users
          sections.splice(index, 1);
        }
      });
    }
    return sections;
  };

  const {
    isSuccess,
    error,
    data: response,
  } = useQuery(
    ['event', eventId],
    () => {
      if (!eventId) {
        return;
      }
      return axios.get(`/events/${eventId}`);
    },
    {
      onSuccess: ({data}) => {
        // Update Event details (optionally with Gallery and AI credit details) upon Event details-relevant page load.
        fetchAndUpdateCreditsSpent(eventId);

        updateContextValues({event: data});
      },
    },
  );

  useEffect(() => {
    let initialEventSections = [...EVENT_SECTIONS];

    // Run this in 'useEffect' to ensure Booth is only added to sidebar once.
    initialEventSections =
      removeVirtualBoothFromSidebarIfClientUser(initialEventSections);
    const sections = initialEventSections.map((section) => {
      const { items, ...restSection } = section;

      return {
        ...restSection,
        items: generateItemsURLs(items, EVENT_URL_PATTERN, eventId),
      };
    });

    setSections(sections);

    // To clean up the event object before it will be populated with useQuery
    updateContextValues({ event: null });

    return () => {
      setSections([]);
    };
  }, [eventId]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        sections={sections}
        navBarHeader={event?.display_name}
      />
      <div className={classes.wrapper}>
        <HTTPErrorHandler status={response?.status || error?.response?.status}>
          {!!event && isSuccess && (
            <div className={classes.contentContainer}>
              <div className={classes.content}>{children}</div>
            </div>
          )}
        </HTTPErrorHandler>
      </div>
    </div>
  );
};

EventLayout.propTypes = {
  children: PropTypes.node,
};

export default EventLayout;
