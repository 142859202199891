import NotFound from '../NotFound';
import Forbidden from '../Forbidden';

const HTTPErrorHandler = ({ status, children }) => {
  return (
    <>
      {status >= 200 && status < 400 && children}
      {status === 404 && <NotFound />}
      {status === 403 && <Forbidden />}
    </>
  );
};

export default HTTPErrorHandler;
