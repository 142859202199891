import React from 'react';
import { Box, Button, Hidden, Tab, Tabs } from '@material-ui/core';
import { Download as ExportIcon } from 'react-feather';

const TabToolbar = ({ tabs, onTabChange, currentTab, onExport }) => {
  // Wrapper function incapsulates prop handler
  // Added if we need to add some other logic
  const handleTabsChange = (event, tabValue) => {
    onTabChange(tabValue);
  };

  const handleExport = () => {
    onExport(false);
  };

  const selectedTab =
    tabs.find((tab) => {
      return tab.value === currentTab;
    }) || tabs[0];

  return (
    <Box display="flex">
      <Tabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="secondary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.label} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <Hidden smDown>
        <Box flex="1 auto" />
        <Button startIcon={<ExportIcon height={18} />} onClick={handleExport}>
          Export {selectedTab.exportLabel}
        </Button>
      </Hidden>
    </Box>
  );
};

export default TabToolbar;
