import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { PlusCircle as PlusCircleIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {},
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Header = ({ className, isAdmin = false, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item className={classes.gridItem}>
        <Typography variant="h3" color="textPrimary">
          All Events
        </Typography>
      </Grid>
      {isAdmin && (
        <Grid item className={classes.gridItem}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            component={RouterLink}
            to="/events/create"
            startIcon={
              <SvgIcon fontSize="small">
                <PlusCircleIcon />
              </SvgIcon>
            }
          >
            New Event
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
