import { get, post, put } from 'api/api-helper';
import { isURL } from 'helpers';
import download from 'downloadjs';

/**
 * Collection resource helper to request info.
 * @param {String|URL} collectionURL - absolute path to collection resource.
 * @returns {RequestResult} - result of api call.
 */
export const getCollection = async (collectionURL) => {
  return get(
    isURL(collectionURL) ? collectionURL : `/collections/${collectionURL}`,
  );
};

export const createCollection = async (collectionData) => {
  return post(`/collections`, collectionData);
};

export const updateCollection = async (collectionURL, collectionData) => {
  return put(collectionURL, collectionData);
};

export const exportCollection = async (collectionURL, params) => {
  return post(collectionURL, params);
};

export const downloadCollectionExport = (exportInfo, eventName) => {
  const { download_url } = exportInfo;
  const x = new XMLHttpRequest();
  x.open('GET', download_url, true);
  x.responseType = 'blob';
  x.onload = function (e) {
    download(e.target.response, eventName + ' Archive', 'application/zip');
  };
  x.send();
  return x;
};
