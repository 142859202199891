import _ from 'lodash';
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { softShadows } from 'theme/shadows';
import typography from 'theme/typography';

const baseOptions = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.075)',
      },
    },
    MuiTypography: {
      overline: {
        color: '#546e7a',
      },
    },
  },
};

const themeOption = {
  name: 'LIGHT',
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: colors.grey[100],
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.grey[600],
    },
    background: {
      default: colors.common.white,
      dark: '#F9F9FA',
      paper: colors.common.white,
    },
    primary: {
      main: '#102638',
    },
    secondary: {
      main: '#233C5E',
    },
    button: {
      main: '#233C5E',
      hover: '#102638',
    },
    text: {
      link: '#102638',
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows: softShadows,
};

export const createTheme = (config = {}) => {
  if (!themeOption) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOption, { direction: config.direction }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
