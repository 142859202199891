import axios from 'axios';
import qs from 'qs';
import {
  AuthenticationContext,
  signinSilent,
  getUserManager,
} from '@axa-fr/react-oidc-context';
import { useContext, useEffect, useState } from 'react';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// Global vars to persist token state.
// useState and local vars don't work because interceptors are not recreated on time after changes.
let OIDC_USER = null;
let signInPromise = null;

/**
 * Configuration function. Sets base URL for all requests.
 * Also defines query params serialization
 */
export const useSetupDal = () => {
  const [isDALReady, setDALReady] = useState();

  const { oidcUser, isLoggingOut, isLoading, userManager, login, logout } =
    useContext(AuthenticationContext);

  // Handling scenario when silent renew fails
  userManager.events.addSilentRenewError(() => {
    logout();
  });

  // Effect that stores user info after token renew.
  useEffect(() => {
    if (oidcUser) {
      OIDC_USER = oidcUser;
    }
  }, [oidcUser]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!oidcUser && !isLoggingOut) {
      login();
      return;
    }

    axios.defaults.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: 'repeat',
        skipNulls: true,
      });
    };

    axios.interceptors.request.use(
      (req) => {
        req.headers = {
          Authorization: `${OIDC_USER.token_type} ${OIDC_USER.access_token}`,
        };

        return req;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401) {
          if (!signInPromise) {
            const signinFn = signinSilent(getUserManager);
            signInPromise = signinFn();
          }

          if (!originalRequest._retry) {
            originalRequest._retry = true;

            // Not using await here to be able to handle requests that happen simultaneously
            return signInPromise.then((userData) => {
              // Storing into var, to be used in request interceptor, because oidcUser from context not yet updated
              OIDC_USER = userData;

              signInPromise = null;

              return axios(originalRequest);
            });
          }
        }

        return error;
      },
    );

    setDALReady(true);
  }, [OIDC_USER, oidcUser, isLoading]);

  return isDALReady;
};

export const request = axios;
