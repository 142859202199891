import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const AuthenticationSpinner = () => (
  <Box
    minHeight="100vh"
    width="100vw"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress variant="indeterminate" style={{ color: '#102537' }} />
  </Box>
);

export default AuthenticationSpinner;
