import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Check as CheckIcon } from 'react-feather';
import { downloadCollectionExport } from '../../../api/collection';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  footer: {
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const DownloadDialog = ({ open, close, event, exportInfo }) => {
  const classes = useStyles();
  const [downloadRequest, setDownloadRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  let downloadBtnTitle = '';

  const onDownload = async () => {
    setLoading(true);
    setDownloadRequest(
      downloadCollectionExport(exportInfo, event.display_name),
    );
  };

  useEffect(() => {
    setLoading(!exportInfo?.download_url);
  }, [exportInfo?.download_url]);

  useEffect(() => {
    if (downloadRequest?.readyState === 4) {
      close();
    }
  }, [downloadRequest?.readyState]);

  if (loading) {
    downloadBtnTitle = 'Downloading';
  } else {
    if (!exportInfo?.download_url) {
      downloadBtnTitle = 'Preparing gallery';
    } else {
      downloadBtnTitle = 'Download gallery';
    }
  }

  return (
    <Dialog open={open} onClose={close} maxWidth="xs">
      <DialogTitle disableTypography={true} className={classes.title}>
        <Typography variant="h3">Export Photos</Typography>
      </DialogTitle>
      <DialogContent>
        <Box px={3}>
          <Typography variant="subtitle2" color="textSecondary" align="center">
            We are preparing your photos. This usually only takes a few moments but may take 2-5 minutes for galleries
            with videos or that contain thousands of photos, and up to 10+ minutes for galleries with tens of thousands.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height={130}
          mb={1}
          mt={3}
        >
          {loading && (
            <CircularProgress
              variant="indeterminate"
              color="secondary"
              size={90}
            />
          )}
          {!loading && (
            <IconButton color="secondary">
              <CheckIcon size={90} />
            </IconButton>
          )}
        </Box>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          onClick={onDownload}
          variant="contained"
          color="secondary"
          disabled={!exportInfo?.download_url || loading}
        >
          {downloadBtnTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
