import { useContext } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import Header from './Header';
import AdminEvents from './admin/Events';
import UserEvents from './user/Events';
import EventContext from 'contexts/event';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { isAdminUser, isClientUser } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const Events = () => {
  const { oidcUser } = useReactOidc();
  const classes = useStyles();

  const {
    events: { data },
  } = useContext(EventContext);

  return (
    <Page className={classes.root} title="Events">
      <Box
        my={{ xs: 1, md: 2 }}
        className={classes.contentContainer}
        flex="1 auto"
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Header isAdmin={isAdminUser(oidcUser)} />
        <Box mt={2} flex="1 auto" display="flex" flexDirection="column">
          {isAdminUser(oidcUser) && <AdminEvents />}
          {isClientUser(oidcUser) && <UserEvents events={data} />}
        </Box>
      </Box>
    </Page>
  );
};

export default Events;
