import { createContext } from 'react';
import noop from 'lodash/noop';

const EventContext = createContext({
  event: null,
  events: {
    data: [],
    response: null,
  },
  loading: false,
  updateContextValues: (valuesToUpdate = {}, callback = noop) => {},
});

export default EventContext;
