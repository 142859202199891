import isLength from 'validator/lib/isLength';
import isInt from 'validator/lib/isInt';
import isURL from 'validator/lib/isURL';
import isEmpty from 'validator/lib/isEmpty';
import { get, isString, isPlainObject, isEmpty as isEmptyObject } from 'lodash';

export const isDataValid = (dataPath, validations) => {
  return !get(validations, dataPath).errors.length;
};

/*
 * Returns list of error messages if data is invalid.
 */
export const getDataValidationErrors = (dataPath, validations) => {
  return get(validations, dataPath)?.errors;
};

const INITIAL_VALIDATIONS = {
  event: {
    display_name: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Field cannot be empty',
        },
        {
          validator: isLength,
          validatorParams: { min: 3, max: undefined },
          errorMessage: 'Name should be at least 3 characters long',
        },
      ],
      errors: [],
    },
    credits_purchased: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Field cannot be empty',
        },
        {
          validator: isInt,
          validatorParams: { min: 1, max: +Infinity },
          errorMessage:
            'Value must be a whole number and cannot be less than 1',
        },
      ],
      errors: [],
    },
    account: {
      validators: [
        {
          validator: (value, params) => {
            if (isString(value)) {
              return isLength(value, params);
            }
            if (isPlainObject(value)) {
              return !isEmptyObject(value);
            }
          },
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Account must be selected',
        },
      ],
      errors: [],
    },
    manager: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Field cannot be empty',
        },
      ],
      errors: [],
    },
  },
  gallery: {
    display_name: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Field cannot be empty',
        },
      ],
      errors: [],
    },
    capture_url: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Field cannot be empty',
        },
        {
          validator: isURL,
          validatorParams: {
            protocols: ['https', 'http'],
            require_protocol: true,
            require_valid_protocol: true,
          },
          errorMessage:
            'Please enter a valid URL. Check that the URL starts with https://',
        },
      ],
      errors: [],
    },
    account: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Account must be selected',
        },
      ],
      errors: [],
    },
  },
  collection: {
    display_name: {
      validators: [
        {
          validator: isEmpty,
          errorMessage: 'Field cannot be empty',
        },
      ],
      errors: [],
    },
    account: {
      validators: [
        {
          validator: isLength,
          validatorParams: { min: 1, max: undefined },
          errorMessage: 'Account must be selected',
        },
      ],
      errors: [],
    },
  },
};

export default INITIAL_VALIDATIONS;
