import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from 'components/NavBar';
import TopBar from 'components/TopBar';
import {
  AlertCircle as AlertCircleIcon,
  Layout as LayoutIcon,
  Calendar as CalendarIcon,
} from 'react-feather';
import { isAdminUser } from '../../helpers';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const userSections = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: LayoutIcon,
        href: '/dashboard',
      },
      {
        title: 'Events',
        icon: CalendarIcon,
        href: '/events',
      },
      {
        title: 'Resources',
        icon: AlertCircleIcon,
        href: '/resources',
      },
    ],
  },
];

const adminSections = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        icon: LayoutIcon,
        href: '/dashboard',
      },
      {
        title: 'Resources',
        icon: AlertCircleIcon,
        href: '/resources',
      },
    ],
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Events',
        icon: CalendarIcon,
        href: '/events',
      },
    ],
  },
];

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const sections = isAdminUser(oidcUser) ? adminSections : userSections;

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        sections={sections}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
