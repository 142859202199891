import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { ArrowDropDown } from '@material-ui/icons';
import { getDataValidationErrors, isDataValid } from '../validations';

const EventDetails = ({ checkValidation, onChange, event, validations }) => (
  <Box>
    <Grid container direction="row" spacing={2}>
      <Grid item lg={5} md={6} xs={12}>
        <Box p={3} pr={0}>
          <TextField
            color="secondary"
            error={!isDataValid('event.display_name', validations)}
            helperText={
              getDataValidationErrors('event.display_name', validations)[0]
            }
            fullWidth
            name="event-name"
            label="Event Name"
            variant="outlined"
            value={event.display_name}
            onBlur={(event) => {
              checkValidation('event.display_name', event.target.value);
            }}
            onChange={(event) => {
              checkValidation(
                'event.display_name',
                event.target.value.trimLeft(),
              );
              onChange(event.target.name, event.target.value.trimLeft());
            }}
          />
        </Box>
      </Grid>
      <Grid item lg={5} md={6} xs={12}>
        <Box p={3} pr={0}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DatePicker
                allowKeyboardControl
                disableToolbar
                color={'secondary'}
                variant="inline"
                format="MM/DD/yyyy"
                margin="none"
                name="start_time"
                inputVariant="outlined"
                id="date-picker-start-time-inline"
                label="Start Date"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle">
                        <ArrowDropDown />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoOk
                value={event?.start_time || null}
                onChange={(date) => {
                  onChange('start_time', date.toISOString());
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <DatePicker
                allowKeyboardControl
                disableToolbar
                color={'secondary'}
                variant="inline"
                format="MM/DD/yyyy"
                margin="none"
                inputVariant="outlined"
                id="date-picker-end-time-inline"
                label="End Date"
                autoOk
                minDate={new Date(event?.start_time)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle">
                        <ArrowDropDown />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name="end_time"
                value={event?.end_time || null}
                onChange={(date) => {
                  onChange('end_time', date.toISOString());
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default EventDetails;
