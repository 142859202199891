import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { QueryClient, QueryClientProvider } from 'react-query';

import './App.scss';

import Dashboard from 'containers/dashboard';
import Resources from 'containers/resources';
import { useSetupDal } from 'api';
import { createTheme } from 'theme';
import useSettings from 'hooks/useSettings';
import DashboardLayout from 'layouts/DashboardLayout';
import EventLayout from 'layouts/EventLayout';
import GlobalStyles from 'components/GlobalStyles';
import EventDetails from 'containers/event-details';
import GalleryOverview from 'containers/gallery-overview';
import VirtualBoothOverview from 'containers/vbooth-overview';
import Moderation from 'containers/moderation';
import Analytics from 'containers/analytics';
import { isAdminUser } from './helpers';
import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import KeenProvider from './contexts/keen/provider';
import EventProvider from './contexts/event/provider';
import Events from './containers/events';
import NewEvent from './containers/event-creation';
import AuthenticationSpinner from './components/AuthenticationSpinner';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const jss = create({ plugins: [...jssPreset().plugins] });

const App = () => {
  const { settings } = useSettings();
  const { oidcUser, isLoggingOut } = useContext(AuthenticationContext);

  const isDALReady = useSetupDal();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <QueryClientProvider client={queryClient}>
              <div className="pb-admin-app">
                {isLoggingOut && <AuthenticationSpinner />}
                {isDALReady && !isLoggingOut && (
                  <Router>
                    <GlobalStyles />
                    <EventProvider>
                      <KeenProvider>
                        <Switch>
                          <Route exact path="/events/:eventId/booth">
                            <EventLayout>
                              <VirtualBoothOverview />
                            </EventLayout>
                          </Route>
                          <Route exact path="/events/:eventId/moderation">
                            <EventLayout>
                              <Moderation />
                            </EventLayout>
                          </Route>
                          <Route exact path="/events/:eventId/gallery">
                            <EventLayout>
                              <GalleryOverview />
                            </EventLayout>
                          </Route>
                          <Route exact path="/events/:eventId/edit">
                            <DashboardLayout>
                              <NewEvent />
                            </DashboardLayout>
                          </Route>
                          <Route exact path="/events/:eventId/analytics">
                            <EventLayout>
                              <Analytics />
                            </EventLayout>
                          </Route>
                          <Route exact path="/events/create">
                            <DashboardLayout>
                              <NewEvent />
                            </DashboardLayout>
                          </Route>
                          <Route exact path="/events/:eventId">
                            <EventLayout>
                              <EventDetails />
                            </EventLayout>
                          </Route>
                          <Route exact path="/events">
                            <DashboardLayout>
                              <Events />
                            </DashboardLayout>
                          </Route>
                          <Route exact path="/dashboard">
                            <DashboardLayout>
                              <Dashboard />
                            </DashboardLayout>
                          </Route>
                          <Route exact path="/resources">
                            <DashboardLayout>
                              <Resources />
                            </DashboardLayout>
                          </Route>
                          <Route exact path="/">
                            <Redirect
                              to={
                                isAdminUser(oidcUser) ? '/events' : '/dashboard'
                              }
                            />
                          </Route>
                        </Switch>
                      </KeenProvider>
                    </EventProvider>
                  </Router>
                )}
              </div>
            </QueryClientProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
