import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  InputAdornment,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  makeStyles,
  Button,
  Grid,
  TablePagination,
  Hidden,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  Table,
} from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  XCircle as ClearIcon,
} from 'react-feather';
import EventContext from '../../../contexts/event';
import { getEvents } from '../../../api/events';
import EventEntry from 'components/EventEntry';
import tabs from '../eventsTabs';
import Spinner from '../../../components/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 auto',
    display: 'flex',
  },
  contentBox: {
    width: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  queryField: {
    width: 500,
  },
  scrollGrid: {
    alignContent: 'flex-start',
    flex: '1 auto',
    marginTop: theme.spacing(3),
  },
  queryFieldMobile: {
    '& .MuiInput-root': {
      paddingLeft: theme.spacing(2),
    },
  },
  mobileTable: {
    marginTop: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f9f9fa !important',
    },
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const UserEvents = ({ className, ...rest }) => {
  const classes = useStyles();
  let history = useHistory();
  const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState('');

  const [totalEventsCount, setTotalEventsCount] = useState(0);

  const {
    updateContextValues,
    loading,
    events: eventsData,
  } = useContext(EventContext);
  const { response, data: events } = eventsData;

  useEffect(async () => {
    setTotalEventsCount(response?.headers['x-total-count'] - 0 || 0);
  }, [response]);

  /*
   * On tab change we fetch events with corresponding state
   */
  useEffect(async () => {
    updateContextValues({ loading: true });
    const { data, response } = await getEvents({
      state: currentTab === 'all' ? null : currentTab,
      q: query,
      page: page + 1,
      per_page: limit,
    });
    updateContextValues({ events: { data, response }, loading: false });
  }, [currentTab, page, limit]);

  /*
   * Effect created to handle clear button behavior.
   *  If we call search directly, setQuery hasn't cleared query
   */
  useEffect(async () => {
    if (!query) {
      searchEvents();
    }
  }, [query]);

  const searchEvents = async (forcedQuery) => {
    updateContextValues({ loading: true });
    const { data, response } = await getEvents({
      state: currentTab === 'all' ? null : currentTab,
      q: forcedQuery !== undefined ? forcedQuery : query,
    });
    updateContextValues({ events: { data, response }, loading: false });
  };

  const handleTabsChange = (event, value) => {
    setQuery('');
    setPage(0);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.key === 'Enter') {
      searchEvents();
    }
  };

  return (
    <Box display="flex" flexDirection="column" flex="1 auto">
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box
          className={classes.contentBox}
          display="flex"
          flexDirection="column"
          flex="1 auto"
        >
          <Box display="flex">
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {loading && <Spinner style={{ justifyContent: 'flex-end' }} />}
          </Box>
          <Divider />
          <Box pt={3} minHeight={56} display="flex" alignItems="center">
            <Hidden mdUp>
              <TextField
                size="small"
                color="secondary"
                fullWidth
                className={classes.queryFieldMobile}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: !!query ? (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setQuery('');
                          searchEvents(null);
                        }}
                      >
                        <ClearIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ) : undefined,
                }}
                onChange={handleQueryChange}
                onKeyPress={handleKeyPress}
                placeholder="Search events"
                value={query}
                variant="standard"
              />
            </Hidden>
            <Hidden smDown>
              <TextField
                size="small"
                className={classes.queryField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: !!query ? (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                        style={{
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setQuery('');
                          searchEvents(null);
                        }}
                      >
                        <ClearIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ) : undefined,
                }}
                onChange={handleQueryChange}
                onKeyPress={handleKeyPress}
                placeholder="Search events"
                value={query}
                color="secondary"
                variant="outlined"
              />
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  searchEvents();
                }}
              >
                SEARCH
              </Button>
            </Hidden>
            <Box flexGrow={1} />
          </Box>
          <Hidden smDown>
            <Grid container spacing={2} className={classes.scrollGrid}>
              {events.map((event) => {
                return (
                  <Grid key={event.id} item md={6}>
                    <EventEntry event={event} withLabel={false} />
                  </Grid>
                );
              })}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Table className={classes.mobileTable} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell align="center">View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => {
                  return (
                    <TableRow
                      hover
                      key={event.id}
                      className={classes.tableRow}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        history.push(`/events/${event.id}`);
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="subtitle2" color="textPrimary">
                            {event.display_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center" style={{ width: 100 }}>
                        <IconButton
                          onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            history.push(`/events/${event.id}`);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Hidden>
          <Box display="flex" mt="auto">
            <Box flex="1 auto" />
            <TablePagination
              component="div"
              labelRowsPerPage="Events per page"
              count={totalEventsCount}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[25, 50, 100]}
              SelectProps={{
                SelectDisplayProps: {
                  style: {
                    lineHeight: 2,
                  },
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

UserEvents.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
};

UserEvents.defaultProps = {
  events: [],
};

export default UserEvents;
