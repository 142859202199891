import { filter, isRegExp } from 'lodash';
import { ACTIVE, FINISHED, UPCOMING } from '../consts/event-states';
import { ALLOWED_BY_DEFAULT, MUST_REVIEW } from '../consts/moderation-types';
import { ACCEPTED, DENIED, PENDING } from '../consts/moderation-decisions';

export const OK_HTTP_STATUSES = [200, 201];
const CONTACT_YOUR_MANAGER_TEXT =
  'Please contact your Account Manager if you need to purchase Embedding.';
/**
 * Helper that checks if persisted user has token
 */
export const isUserTokenAvailable = (oidcUser) => {
  return !!oidcUser?.access_token;
};

/**
 * Check if user is logged in and user info is persisted
 */
export const isUserLoggedIn = (oidcUser) => {
  return isUserTokenAvailable(oidcUser);
};

/**
 * Returns role of logged user.
 */
export const isStaffUser = (oidcUser) => {
  return oidcUser.profile.staff;
};

/**
 * Checks if logged in user is SuperAdmin.
 */
export const isAdminUser = (oidcUser) => {
  return isUserLoggedIn(oidcUser) && isStaffUser(oidcUser);
};

/**
 * Checks if logged in user is SuperAdmin.
 */
export const isClientUser = (oidcUser) => {
  return isUserLoggedIn(oidcUser) && !isStaffUser(oidcUser);
};

export const filterItemsBy = (items, key, value) => {
  return filter(items, { [key]: value });
};

export const isURL = (urlString) => {
  try {
    const url = new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Returns max value of itemProp within items provided
 * @param {Array} items - items to find within
 * @param {String} itemProp - property name to find by
 * @param {String|Number} initialValue - value for initial comparison
 * @returns {Boolean} - True or False
 */
export const findMaxValue = (items = [], itemProp, initialValue = '') => {
  return items.reduce((acc, item) => {
    if (item[itemProp] > acc) {
      acc = item[itemProp];
    }
    return acc;
  }, initialValue);
};

export const getMediaVariant = (mediaItem, variantId) => {
  const { current_serving_variants = [] } = mediaItem;
  return current_serving_variants.find((item) => {
    return item.name === variantId;
  });
};

/*
 * Returns value in percents a of b
 * @return [Number] percent
 * */
export const toPercentage = (a = 0, b = 0) => {
  if (!b) {
    return 0;
  } else {
    return ((100 * a) / b).toFixed(2) - 0;
  }
};

/*
 * Returns string without first occurence of removePart
 * @param {Number} urlString - URL string
 * @param {RegExp|String} removePart - substring to be removed
 * @return [String] - new url string
 * */
export const removeURLPart = (urlString = '', removePart) => {
  const removeRE = isRegExp(removePart) ? removePart : new RegExp(removePart);
  return urlString.replace(removeRE, '');
};

/*
 * Returns
 * @return [String] label for event card by event state
 * */
export const getEventLabel = (event) => {
  switch (event.state) {
    case UPCOMING:
      return 'Upcoming event';
    case ACTIVE:
      return 'Live event';
    case FINISHED:
      return 'Most recent';
    default:
      return 'Undefined Event';
  }
};

/*
 * Returns
 * @param {String} moderationType - Collection moderation type
 * @param {String} moderation - moderation decision
 * @return String label for event tab by moderation decision and type
 * */
export const getModerationLabel = (moderationType, moderation) => {
  if (moderationType === ALLOWED_BY_DEFAULT) {
    switch (moderation) {
      case ACCEPTED:
        return 'Live Gallery';
      case DENIED:
        return 'Hidden';
    }
  }
  if (moderationType === MUST_REVIEW) {
    switch (moderation) {
      case ACCEPTED:
        return 'Approved';
      case DENIED:
        return 'Hidden';
      case PENDING:
        return 'Needs Approval';
    }
  }
};

/**
 * If the user's current userAgent is IE 11 or earlier, this function will return true. This is used to alert the user
 * of compatibility issues.
 *
 * @returns {boolean}
 */
export const isInternetExplorerUserAgent = () => {
  return (
    /MSIE (\d+\.\d+);/.test(navigator.userAgent) ||
    /Trident\/\d+\.\d+;/.test(navigator.userAgent)
  );
};

/*
 * Returns code snippet for gallery embed code
 * @return [String] gallery embed code snippet
 * */
export const getGalleryEmbedCode = (gallery) => {
  if (!gallery?.embeddable) {
    return CONTACT_YOUR_MANAGER_TEXT;
  }

  return `<iframe src="${gallery?.public_url}" title="Snapbar Photo Booth Gallery" style="width:100%;height:100%;border:none;background-color:#ffffff;margin-top:15px"></iframe>`;
};

/*
 * Returns code snippet for booth embed code
 * @return [String] booth code snippet
 * */
export const getVBoothEmbedCode = (gallery) => {
  if (!gallery?.embeddable) {
    return CONTACT_YOUR_MANAGER_TEXT;
  }

  return `<iframe src="${gallery?.capture_url}" title="Snapbar Virtual Photo Booth" allow="camera *;microphone" style="width:100%;height:100%;border:none"></iframe>`;
};
