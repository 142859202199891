import { useContext, useEffect, useState } from 'react';
import {
  Box,
  colors,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { useParams } from 'react-router';

import Page from 'components/Page';
import CopyButton from 'components/CopyButton';
import HelpButton from 'components/HelpButton';
import Header from './Header';
import EventContext from 'contexts/event';
import { getGallery } from 'api/gallery';
import { getSubmittedMediaCount } from 'api/keen/SubmittedMedia';
import useMountedState from 'hooks/useMountedState';
import {
  getGalleryEmbedCode,
  getVBoothEmbedCode,
  removeURLPart,
  toPercentage,
} from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  gridItem: {
    height: '240px',
  },
  cardDetails: {
    display: 'flex',
  },
  linkButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  linkButtonIcon: {
    color: colors.common.white,
  },
  cardRow: {
    display: 'flex',
    height: '56px',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  cardRowTitle: {
    width: '40%',
  },
  cardRowValue: {
    flex: '1 auto',
  },
  verticalDivider: {
    height: '100%',
    width: '1px',
    backgroundColor: '#eee',
  },
}));

const EventDetails = () => {
  const classes = useStyles();
  const isMounted = useMountedState();
  const { eventId } = useParams();

  const [gallery, setGallery] = useState(null);
  const [takenCount, setTakenCount] = useState(0);
  const { event } = useContext(EventContext);

  useEffect(async () => {
    if (!event) {
      return;
    }

    // To make sure event instance is up to date let's compare url eventId parameter with event ID to avoid useless API calls
    const apis =
      eventId === event?.id ? [getGallery(event?.galleries[0])] : [() => true];
    const [galleryRes] = await Promise.all(apis);

    if (!isMounted()) {
      return;
    }

    const { data: galleryData } = galleryRes;

    setGallery(galleryData);
    setTakenCount(event?.credits_spent || 0);
  }, [event]);

  return (
    <Page className={classes.root} title="Event Details">
      <Box
        className={classes.contentContainer}
        mx={5}
        my={{ xs: 1, md: 2 }}
        flex="1 auto"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <Box mt={5} display="flex" flex="1">
          <Grid container direction="row" spacing={3} alignContent="flex-start">
            <Grid item lg={6} sm={6} xs={12}>
              <Paper>
                <Box
                  p={3}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box flex={1} maxWidth="80%" mr="auto" width="0px">
                    <Typography variant="overline" display="block" gutterBottom>
                      Booth
                    </Typography>
                    <Link href={gallery?.capture_url} target="_blank">
                      <Typography
                        variant="h4"
                        display="block"
                        gutterBottom
                        noWrap
                      >
                        {removeURLPart(gallery?.capture_url, /^https?:\/\//)}
                      </Typography>
                    </Link>
                  </Box>
                  <a
                    href={gallery?.capture_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.linkButton}>
                      <ExternalLinkIcon className={classes.linkButtonIcon} />
                    </IconButton>
                  </a>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <Paper>
                <Box
                  p={3}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box flex={1} maxWidth="80%" mr="auto" width="0px">
                    <Typography variant="overline" display="block" gutterBottom>
                      Gallery
                    </Typography>
                    <Link href={gallery?.public_url} target="_blank">
                      <Typography
                        variant="h4"
                        display="block"
                        gutterBottom
                        noWrap
                      >
                        {removeURLPart(gallery?.public_url, /^https?:\/\//)}
                      </Typography>
                    </Link>
                  </Box>
                  <a
                    href={gallery?.public_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconButton className={classes.linkButton}>
                      <ExternalLinkIcon className={classes.linkButtonIcon} />
                    </IconButton>
                  </a>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Paper>
                <Box
                  height="240px"
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                  justifyContent="space-between"
                >
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <Typography variant="h5">Your Event</Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <Typography variant="subtitle2">Name</Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {event?.display_name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <Typography variant="subtitle2">Start date</Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {moment(event?.start_time).format('MMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <Typography variant="subtitle2">End Date</Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {moment(event?.end_time).format('MMM D, YYYY')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Paper>
                <Box
                  height="240px"
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <Box px={3} className={classes.cardRow}>
                    <Typography variant="h5">Credits</Typography>
                  </Box>
                  <Box display="flex">
                    <Box
                      display="flex"
                      flexDirection="column"
                      flex={1}
                      p={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h3">{takenCount}</Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        gutterBottom
                      >
                        USED
                      </Typography>
                    </Box>
                    <div className={classes.verticalDivider} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      flex={1}
                      p={2}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h3">
                        {event?.credits_purchased}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        gutterBottom
                      >
                        PURCHASED
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box p={2} px={3} alignItems="center" justifyContent="center">
                    <Typography variant="overline" color="textSecondary">
                      Credit use
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h4">
                        {`${toPercentage(
                          takenCount,
                          event?.credits_purchased,
                        )}%`}
                      </Typography>
                      <Box flex={1} pl={2}>
                        <LinearProgress
                          color="secondary"
                          variant="determinate"
                          value={toPercentage(
                            takenCount,
                            event?.credits_purchased,
                          )}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Paper>
                <Box
                  pb={gallery?.embeddable ? 0 : 3}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    px={3}
                    className={classes.cardRow}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Gallery Embed Code</Typography>
                  </Box>
                  <Box px={3} pt={3} flex={1}>
                    <TextField
                      type="textarea"
                      variant="outlined"
                      fullWidth
                      key={gallery}
                      rows={3}
                      multiline
                      disabled={!gallery?.embeddable}
                      rowsMax={4}
                      InputProps={{
                        readOnly: true,
                        style: {
                          borderRadius: 4,
                          backgroundColor: '#f9f9fb',
                          fontSize: '0.875rem',
                          fontFamily: gallery?.embeddable
                            ? 'monospace'
                            : '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      defaultValue={getGalleryEmbedCode(gallery)}
                    />
                  </Box>
                  {gallery?.embeddable && (
                    <Box
                      px={3}
                      py={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <HelpButton />
                      <CopyButton copyContent={getGalleryEmbedCode(gallery)} />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={3} sm={6} xs={12}>
              <Paper>
                <Box
                  pb={gallery?.embeddable ? 0 : 3}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    px={3}
                    className={classes.cardRow}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Booth Embed Code</Typography>
                  </Box>
                  <Box px={3} pt={3} flex={1}>
                    <TextField
                      type="textarea"
                      variant="outlined"
                      fullWidth
                      key={gallery}
                      rows={3}
                      multiline
                      disabled={!gallery?.embeddable}
                      rowsMax={4}
                      InputProps={{
                        readOnly: true,
                        style: {
                          borderRadius: 4,
                          backgroundColor: '#f9f9fb',
                          fontSize: '0.875rem',
                          fontFamily: gallery?.embeddable
                            ? 'monospace'
                            : '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      defaultValue={getVBoothEmbedCode(gallery)}
                    />
                  </Box>
                  {gallery?.embeddable && (
                    <Box
                      px={3}
                      py={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <HelpButton />
                      <CopyButton copyContent={getVBoothEmbedCode(gallery)} />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default EventDetails;
