import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  CloseOutlined,
} from '@material-ui/icons';
import {
  Download as DownloadIcon,
  EyeOff as HideIcon,
  Trash2 as DeleteIcon,
  CheckCircle as ApproveIcon,
} from 'react-feather';
import { getMediaVariant } from '../../helpers';
import moment from 'moment';
import { downloadMedia } from '../../api/media';
import { ACCEPTED, DENIED, PENDING } from '../../consts/moderation-decisions';
import clsx from 'clsx';
import MediaRenderer from '../MediaRenderer';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  card: {
    height: 'auto',
    minWidth: '600px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
  },
  cardActionsVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    position: 'relative',
    flex: '1 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hidden: {
    display: 'none',
  },
  deletePrompt: {
    backgroundColor: '#FFFFFF',
    bottom: 0,
    width: '100%',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '72vh',
  },
  closeBtn: {
    position: 'absolute',
    top: '-50px',
    right: '-50px',
  },
  actionMargin: {
    margin: '0 10px',
  },
  descriptionMargin: {
    margin: '0 26px',
  },
}));

const LightboxDesktop = ({
  moderation,
  selectedMediaItem = {},
  navNext,
  navPrev,
  close,
  onHide,
  onDelete,
  onAccept,
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const mediaVariant = getMediaVariant(selectedMediaItem, 'original');
  const { filename, update_time } = mediaVariant;

  return (
    <div
      className={classes.wrapper}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton onClick={navPrev} color="inherit" disabled={!navPrev}>
        <ArrowBackOutlined fontSize="large" />
      </IconButton>
      <Card className={classes.card}>
        <IconButton
          onClick={close}
          color="inherit"
          style={{ color: '#fff', zIndex: 2 }}
          className={classes.closeBtn}
        >
          <CloseOutlined fontSize="large" />
        </IconButton>
        <CardMedia className={classes.cardMedia}>
          <MediaRenderer
            mediaVariant={mediaVariant}
            media={selectedMediaItem}
            muted={false}
            loop={false}
            playing={true}
            controls
            responsive
          />
          <Box
            className={clsx(classes.deletePrompt, {
              [classes.hidden]: !isExpanded,
            })}
            position="absolute"
            py={2}
          >
            <Typography variant="h3" align="center">
              Are you sure?
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              align="center"
            >
              Do you really want to permantely delete this media?
              <br />
              You can not undo this action.
            </Typography>
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setIsExpanded(false);
                }}
              >
                Cancel
              </Button>
              <Box width={50} />
              <Button
                color="primary"
                variant="outlined"
                onClick={async () => {
                  setIsExpanded(false);
                  await onDelete(selectedMediaItem);
                  if (navNext) {
                    navNext();
                  } else {
                    close();
                  }
                }}
                startIcon={<DeleteIcon height={20} />}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </CardMedia>
        <CardActions className={classes.cardActionsVertical}>
          <Box
            mt={0.5}
            mb={1}
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <span className={classes.descriptionMargin}>{filename}</span>
            <span className={classes.descriptionMargin}>
              {moment(update_time).format('MMM D, YYYY')}
            </span>
          </Box>
          <Box
            mt={1}
            mb={0.5}
            width="100%"
            display="flex"
            justifyContent="center"
          >
            {moderation === PENDING && (
              <>
                <Button
                  className={classes.actionMargin}
                  color="secondary"
                  variant="contained"
                  onClick={async () => {
                    await onAccept(selectedMediaItem);
                    if (navNext) {
                      navNext();
                    } else {
                      close();
                    }
                  }}
                  startIcon={<ApproveIcon height={20} />}
                >
                  Approve
                </Button>
                <Button
                  className={classes.actionMargin}
                  color="secondary"
                  variant="contained"
                  onClick={async () => {
                    await onHide(selectedMediaItem);
                    if (navNext) {
                      navNext();
                    } else {
                      close();
                    }
                  }}
                  startIcon={<HideIcon height={20} />}
                >
                  Hide
                </Button>
              </>
            )}
            {moderation === DENIED && (
              <Button
                className={classes.actionMargin}
                color="secondary"
                variant="contained"
                onClick={async () => {
                  await onAccept(selectedMediaItem);
                  if (navNext) {
                    navNext();
                  } else {
                    close();
                  }
                }}
                startIcon={<ApproveIcon height={20} />}
              >
                Approve
              </Button>
            )}
            {moderation === ACCEPTED && (
              <Button
                className={classes.actionMargin}
                color="secondary"
                variant="contained"
                onClick={async () => {
                  await onHide(selectedMediaItem);
                  if (navNext) {
                    navNext();
                  } else {
                    close();
                  }
                }}
                startIcon={<HideIcon height={20} />}
              >
                Hide
              </Button>
            )}
            <Button
              className={classes.actionMargin}
              color="secondary"
              variant="contained"
              onClick={() => {
                downloadMedia(selectedMediaItem);
              }}
              startIcon={<DownloadIcon height={20} />}
            >
              Download
            </Button>
            <Button
              className={classes.actionMargin}
              color="secondary"
              variant="contained"
              onClick={async () => {
                setIsExpanded(true);
              }}
              startIcon={<DeleteIcon height={20} />}
            >
              Delete
            </Button>
          </Box>
        </CardActions>
      </Card>
      <IconButton
        onClick={navNext}
        size="medium"
        color="inherit"
        disabled={!navNext}
      >
        <ArrowForwardOutlined fontSize="large" />
      </IconButton>
    </div>
  );
};

export default LightboxDesktop;
