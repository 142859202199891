import { useContext, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  colors,
  IconButton,
  makeStyles,
  Paper,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get, set } from 'lodash';
import {
  EventOutlined as EventIcon,
  PhotoAlbumOutlined as PhotoIcon,
  CheckCircle as CheckIcon,
} from '@material-ui/icons';
import Page from 'components/Page';
import { Camera as CameraIcon, PlusCircle as AddIcon } from 'react-feather';
import Header from './Header';
import { useHistory, useParams } from 'react-router-dom';
import EventContext from '../../contexts/event';
import { createGallery, getGallery, updateGallery } from 'api/gallery';
import {
  createCollection,
  getCollection,
  updateCollection,
} from 'api/collection';
import { createEvent, updateEvent, getEvent } from 'api/events';
import { getAccounts } from 'api/accounts';
import EventDetails from './steps/EventDetails';
import StepTitle from 'components/StepTitle';
import StepSubTitle from 'components/StepSubTitle';
import VirtualBooth from './steps/VirtualBooth';
import Gallery from './steps/Gallery';
import Final from './steps/Final';
import eventReducer from './reducer';
import {
  initCollectionState,
  initEventState,
  initGalleryState,
} from './initialStates';

import INITIAL_VALIDATIONS from './validations';
const OK_HTTP_STATUSES = [200, 201];

// Defined validated fields
// because didn't like how recursive method was implemented.
// TODO (andrewhalych) Possible place for refactoring.
const FIELDS_FOR_VALIDATION = [
  'event.account',
  'event.display_name',
  'event.credits_purchased',
  'gallery.capture_url',
  'gallery.display_name',
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  paper: {
    width: '100%',
  },
  gridItem: {
    height: '240px',
  },
  cardDetails: {
    display: 'flex',
  },
  linkButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  linkButtonIcon: {
    color: colors.common.white,
  },
  cardRow: {
    display: 'flex',
    height: '56px',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  cardRowTitle: {
    width: '40%',
  },
  cardRowValue: {
    flex: '1 auto',
  },
  verticalDivider: {
    height: '100%',
    width: '1px',
    backgroundColor: '#eee',
  },
  switchLabel: {
    marginLeft: 0,
  },
  galleryTextContainer: {
    height: '100%',
  },
  galleryText: {
    height: '100%',
    flex: '1 auto',
  },
  stepContent: {
    marginLeft: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  stepIcon: {
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#B0BEC5',
    justifyContent: 'center',
    color: 'white',
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -6px rgba(0,0,0,0.25)',
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow:
        '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -6px rgba(0,0,0,0.25)',
    },
    [theme.breakpoints.down('md')]: {
      width: '32px',
      height: '32px',
      padding: 0,
    },
  },
}));

const STEPS = ['event-details', 'virtual-booth', 'gallery', 'finalize'];
const NewEvent = () => {
  const classes = useStyles();

  let history = useHistory();

  const [created, setCreated] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [accounts, setAccounts] = useState([]);

  const [validations, setValidations] = useState(INITIAL_VALIDATIONS);
  // TODO show spinner while event is loading
  const { loading, updateContextValues } = useContext(EventContext);

  const intiReducer = () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return {
      created: false,
      event: {
        ...initEventState,
        timezone: userTimeZone,
      },
      gallery: initGalleryState,
      collection: initCollectionState,
    };
  };

  const [eventState, eventDispatch] = useReducer(eventReducer, {}, intiReducer);

  const { gallery, event, collection } = eventState;

  // Cast initial value of credits_purchased to a string as validator library expects a string.
  event.credits_purchased = String(event.credits_purchased);

  const params = useParams();
  const { eventId } = params;

  useEffect(async () => {
    if (!eventId) {
      return;
    }
    updateContextValues({ loading: true });
    // TODO Add error handling if something goes wrong
    const { data } = await getEvent(eventId);
    if (!data) {
      updateContextValues({ loading: false });
      return;
    }
    eventDispatch({ type: 'event', data: data });

    const galleryRes = getGallery(data?.galleries[0]);
    const collectionRes = getCollection(data?.collection);

    const { data: galleryData } = await galleryRes;
    const { data: collectionData } = await collectionRes;

    eventDispatch({ type: 'collection', data: collectionData });
    eventDispatch({ type: 'gallery', data: galleryData });

    updateContextValues({ loading: false });
  }, [eventId]);

  useEffect(async () => {
    const accountsResult = await getAccounts({ per_page: 2500 });
    // TODO (andrewhalych) Add error handling
    const { data } = accountsResult;
    if (data) {
      setAccounts(data);
    }
  }, []);

  const doEventCreate = async () => {
    const {
      data: collectionData,
      status: collectionStatus,
      error: collectionError,
    } = await createCollection(collection);
    // TODO (andrewhalych) Add UI to show error if any appear and pull status validation into helper fn
    if (!OK_HTTP_STATUSES.includes(collectionStatus)) {
      console.error(collectionError);
      return;
    }
    await eventDispatch({ type: 'collection', data: collectionData });

    const {
      data: galleryData,
      status: galleryStatus,
      error: galleryError,
    } = await createGallery({ ...gallery, collection: collectionData.url });

    if (!OK_HTTP_STATUSES.includes(galleryStatus)) {
      console.error(galleryError);
      return;
    }

    await eventDispatch({ type: 'gallery', data: galleryData });

    const { data: eventData, status: eventStatus } = await createEvent({
      ...event,
      collection: collectionData.url,
      galleries: [galleryData.url],
    });

    eventDispatch({ type: 'event', data: eventData });

    await updateContextValues({ loading: false });

    setCreated(true);
  };

  const doEventUpdate = async () => {
    const {
      data: collectionData,
      status: collectionStatus,
      error: collectionError,
    } = await updateCollection(event.collection, collection);
    // TODO (andrewhalych) Add UI to show error if any appear
    if (!OK_HTTP_STATUSES.includes(collectionStatus)) {
      console.log(collectionError);
      return;
    }

    await eventDispatch({ type: 'collection', data: collectionData });

    const {
      data: galleryData,
      status: galleryStatus,
      error: galleryError,
    } = await updateGallery(event.galleries[0], gallery);

    if (!OK_HTTP_STATUSES.includes(galleryStatus)) {
      console.log(galleryError);
      return;
    }

    await eventDispatch({ type: 'gallery', data: galleryData });

    const { data: eventData, status: eventStatus } = await updateEvent(
      event.url,
      event,
    );

    eventDispatch({ type: 'event', data: eventData });

    await updateContextValues({ loading: false });

    history.goBack();
  };

  const isFormValid = () => {
    const validations = [];

    FIELDS_FOR_VALIDATION.forEach((fieldPath) => {
      validations.push(
        isFieldValueValid(fieldPath, get(eventState, fieldPath)),
      );
    });

    return validations.every((value) => !!value);
  };

  const isFieldValueValid = (dataPath, value) => {
    const newValidations = { ...validations };
    if (dataPath) {
      const { validators } = get(validations, dataPath);

      const errors = [];
      validators.forEach((validatorCfg) => {
        const { validator, validatorParams, errorMessage } = validatorCfg;
        if (!validator(value, validatorParams)) {
          errors.push(errorMessage);
        }
      });

      set(
        newValidations,
        [...dataPath.split('.'), 'errors'],
        errors.filter(Boolean),
      );
      setValidations(newValidations);

      return !errors.length;
    }
  };

  const saveEvent = async () => {
    if (!isFormValid()) {
      return;
    }

    await updateContextValues({ loading: true });
    if (!eventId) {
      await doEventCreate();
    } else {
      await doEventUpdate();
    }
  };

  const handleFieldChange = (fieldName, value) => {
    eventDispatch({ type: fieldName, data: value });
  };

  /* Override styles on StepConnector to align properly side to side when in vertical orientation. */
  const VerticalStepConnector = withStyles({
    vertical: {
      marginLeft: 24,
    },
  })(StepConnector);

  return (
    <Page className={classes.root} title="New Event Page">
      <Box
        mx={5}
        my={2}
        flex="1 auto"
        display="flex"
        flexDirection="column"
        className={classes.contentContainer}
      >
        <Header />
        <Box mt={5} width="100%" flex="1 auto" display="flex">
          <Paper className={classes.paper}>
            {!created ? (
              <Stepper
                activeStep={activeStep}
                connector={<VerticalStepConnector />}
                variant="elevation"
                orientation="vertical"
                nonLinear
              >
                <Step
                  expanded
                  completed
                  key="event-details"
                  onClick={() => {
                    setActiveStep(STEPS.indexOf('event-details'));
                  }}
                >
                  <StepLabel
                    icon={<EventIcon />}
                    StepIconComponent={({ icon, active, fontSize }) => {
                      return (
                        <IconButton
                          size={fontSize}
                          color="default"
                          className={clsx(classes.stepIcon, { active })}
                        >
                          {icon}
                        </IconButton>
                      );
                    }}
                    StepIconProps={{
                      fontSize: 'medium',
                    }}
                    optional={
                      <StepSubTitle>Add basic event details.</StepSubTitle>
                    }
                  >
                    <StepTitle>Event Details</StepTitle>
                  </StepLabel>
                  <StepContent className={classes.stepContent}>
                    <EventDetails
                      validations={validations}
                      checkValidation={isFieldValueValid}
                      onChange={handleFieldChange}
                      event={event}
                    />
                  </StepContent>
                </Step>
                <Step
                  expanded
                  key="virtual-booth"
                  onClick={() => {
                    setActiveStep(STEPS.indexOf('virtual-booth'));
                  }}
                >
                  <StepLabel
                    icon={<CameraIcon />}
                    StepIconComponent={({ icon, active, fontSize }) => {
                      return (
                        <IconButton
                          size={fontSize}
                          color="default"
                          className={clsx(classes.stepIcon, { active })}
                        >
                          {icon}
                        </IconButton>
                      );
                    }}
                    StepIconProps={{
                      fontSize: 'medium',
                    }}
                    optional={
                      <StepSubTitle>
                        A UID will auto-generate for linking the booth.
                      </StepSubTitle>
                    }
                  >
                    <StepTitle>Booth</StepTitle>
                  </StepLabel>
                  <StepContent className={classes.stepContent}>
                    <VirtualBooth
                      validations={validations}
                      checkValidation={isFieldValueValid}
                      onChange={handleFieldChange}
                      event={event}
                      gallery={gallery}
                    />
                  </StepContent>
                </Step>
                <Step
                  expanded
                  key="gallery"
                  onClick={() => {
                    setActiveStep(STEPS.indexOf('gallery'));
                  }}
                >
                  <StepLabel
                    icon={<PhotoIcon />}
                    StepIconComponent={({ icon, active, fontSize }) => {
                      return (
                        <IconButton
                          size={fontSize}
                          color="default"
                          className={clsx(classes.stepIcon, { active })}
                        >
                          {icon}
                        </IconButton>
                      );
                    }}
                    StepIconProps={{
                      fontSize: 'medium',
                    }}
                    optional={
                      <StepSubTitle>
                        Create the URL and select filtering options for the
                        gallery.
                      </StepSubTitle>
                    }
                  >
                    <StepTitle>Gallery</StepTitle>
                  </StepLabel>
                  <StepContent className={classes.stepContent}>
                    <Gallery
                      validations={validations}
                      checkValidation={isFieldValueValid}
                      classes={classes}
                      gallery={gallery}
                      collection={collection}
                      onChange={handleFieldChange}
                    />
                  </StepContent>
                </Step>
                <Step
                  expanded
                  key="finalize"
                  onClick={() => {
                    setActiveStep(STEPS.indexOf('finalize'));
                  }}
                >
                  <StepLabel
                    icon={<AddIcon />}
                    StepIconComponent={({ icon, active, fontSize }) => {
                      return (
                        <IconButton
                          size={fontSize}
                          color="default"
                          className={clsx(classes.stepIcon, { active })}
                        >
                          {icon}
                        </IconButton>
                      );
                    }}
                    StepIconProps={{
                      fontSize: 'medium',
                    }}
                    optional={
                      <StepSubTitle>
                        Add a Snapbar staff member and double check all event
                        details.
                      </StepSubTitle>
                    }
                  >
                    <StepTitle>Finalize</StepTitle>
                  </StepLabel>
                  <StepContent>
                    <Final
                      validations={validations}
                      checkValidation={isFieldValueValid}
                      accounts={accounts}
                      event={event}
                      gallery={gallery}
                      onChange={handleFieldChange}
                      saveEvent={saveEvent}
                    />
                  </StepContent>
                </Step>
              </Stepper>
            ) : (
              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <CheckIcon fontSize="large" color="secondary" />
                <Box mb={3} />
                <Typography variant="h3">Event Created!</Typography>
                <Box mb={2} />
                <Typography variant="body2" color="textSecondary">
                  View your event to review or edit any details.
                </Typography>
                <Box mb={5} />
                <Button
                  color="secondary"
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    history.push(`/events/${event.id}`);
                  }}
                >
                  VIEW YOUR EVENT
                </Button>
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
    </Page>
  );
};

export default NewEvent;
