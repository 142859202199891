import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  allEventsButton: {
    textAlign: 'right',
  },
  arrowRightIcon: {
    fontSize: '135%',
    marginLeft: 7,
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
}));

const NoEventCard = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={3}>
        <Grid container direction="row" alignItems="flex-start">
          <Grid item xs={6}>
            <Typography
              variant="overline"
              align="left"
              display="block"
              gutterBottom
            >
              Events at a glance
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h1">No live events.</Typography>
        <Divider className={classes.divider} />
        <div className={classes.allEventsButton}>
          <Button variant="text" component={Link} to={`/events`}>
            SEE ALL EVENTS
            <SvgIcon fontSize="small" className={classes.arrowRightIcon}>
              <ArrowRightIcon />
            </SvgIcon>
          </Button>
        </div>
      </Box>
    </Paper>
  );
};

export default NoEventCard;
