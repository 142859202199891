import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Hidden,
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import {
  ArrowDown as DownloadIcon,
  Edit2 as EditIcon,
  EyeOff as HideIcon,
  Search as SearchIcon,
  Trash2 as RemoveIcon,
  CheckCircle as ApproveIcon,
  MoreVertical as MenuIcon,
  Download as ExportIcon,
  XCircle as ClearIcon,
} from 'react-feather';
import { ALLOWED_BY_DEFAULT, MUST_REVIEW } from '../../consts/moderation-types';
import {
  APPROVED_TAB,
  HIDDEN_TAB,
  LATEST_TAB,
  NEED_APPROVAL_TAB,
} from '../../consts/moderation-tabs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  queryField: {
    maxWidth: 320,
    flex: '1 auto',
  },
  queryFieldMobile: {
    flex: '1 auto',
  },
  button: {
    marginLeft: '12px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: theme.palette.background.default,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const contextActions = {
  [ALLOWED_BY_DEFAULT]: {
    [LATEST_TAB]: {
      hide: true,
      approve: false,
    },
    [HIDDEN_TAB]: {
      approve: true,
      hide: false,
    },
  },
  [MUST_REVIEW]: {
    [NEED_APPROVAL_TAB]: {
      hide: true,
      approve: true,
    },
    [HIDDEN_TAB]: {
      approve: true,
      hide: false,
    },
    [APPROVED_TAB]: {
      approve: false,
      hide: true,
    },
  },
};

const MediaActionToolbar = ({
  allSelected,
  currentTab,
  moderationType,
  onApprove,
  onSearch,
  onSelectAll,
  onHide,
  onDelete,
  onDownload,
}) => {
  const classes = useStyles();
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [query, setQuery] = useState('');

  const handleSearch = () => {
    onSearch(query);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.key === 'Enter') {
      onSearch(query);
    }
  };

  return (
    <Box
      pt={{ xs: 1, md: 3 }}
      pb={{ xs: 1, md: 1 }}
      minHeight={56}
      display="flex"
      alignItems="center"
    >
      <Hidden mdUp>
        <TextField
          size="small"
          className={classes.queryFieldMobile}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: !!query ? (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setQuery('');
                    onSearch('');
                  }}
                >
                  <ClearIcon />
                </SvgIcon>
              </InputAdornment>
            ) : undefined,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          placeholder="Filename or YYYY-MM-DD"
          value={query}
          variant="standard"
        />
        <IconButton ref={actionRef} onClick={() => setMenuOpen(true)}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={actionRef.current}
          onClose={() => setMenuOpen(false)}
          open={isMenuOpen}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            key={`select-all ${allSelected}`}
            onClick={() => {
              setMenuOpen(false);
              onSelectAll();
            }}
          >
            <EditIcon className={classes.icon} height={18} />
            {allSelected ? 'Deselect All' : 'Select All'}
          </MenuItem>
          {contextActions[moderationType][currentTab]['approve'] && (
            <MenuItem
              key="approve"
              onClick={() => {
                setMenuOpen(false);
                onApprove();
              }}
            >
              <ApproveIcon className={classes.icon} height={18} />
              Approve
            </MenuItem>
          )}
          {contextActions[moderationType][currentTab]['hide'] && (
            <MenuItem
              key="hide"
              onClick={() => {
                setMenuOpen(false);
                onHide();
              }}
            >
              <HideIcon className={classes.icon} height={18} />
              Hide
            </MenuItem>
          )}
          <MenuItem
            key="delete"
            onClick={() => {
              setMenuOpen(false);
              onDelete();
            }}
          >
            <RemoveIcon className={classes.icon} height={18} />
            Delete
          </MenuItem>
          <MenuItem
            key="download"
            onClick={() => {
              setMenuOpen(false);
              onDownload();
            }}
          >
            <DownloadIcon className={classes.icon} height={18} />
            Download
          </MenuItem>
          <MenuItem
            key="select-all"
            onClick={() => {
              setMenuOpen(false);
              onSelectAll();
            }}
          >
            <ExportIcon className={classes.icon} height={18} />
            Export Gallery
          </MenuItem>
        </Menu>
      </Hidden>
      <Hidden smDown>
        <TextField
          className={classes.queryField}
          size="small"
          color="secondary"
          InputProps={{
            endAdornment: !!query ? (
              <InputAdornment position="start">
                <SvgIcon
                  fontSize="small"
                  color="action"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setQuery('');
                    onSearch('');
                  }}
                >
                  <ClearIcon />
                </SvgIcon>
              </InputAdornment>
            ) : undefined,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
          onChange={handleQueryChange}
          onKeyPress={handleKeyPress}
          placeholder="Filename or YYYY-MM-DD"
          value={query}
          aria-label="Filename or YYYY-MM-DD"
          variant="outlined"
        />
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleSearch}
        >
          SEARCH
        </Button>
        <Box flexGrow={1} />
        <Button
          className={classes.button}
          variant="outlined"
          onClick={onSelectAll}
          startIcon={<EditIcon height={18} />}
        >
          {allSelected ? 'Deselect All' : 'Select All'}
        </Button>
        {contextActions[moderationType][currentTab]['approve'] && (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={onApprove}
            startIcon={<ApproveIcon height={18} />}
          >
            Approve
          </Button>
        )}
        {contextActions[moderationType][currentTab]['hide'] && (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={onHide}
            startIcon={<HideIcon height={18} />}
          >
            HIDE
          </Button>
        )}
        <Button
          onClick={onDelete}
          className={classes.button}
          variant="outlined"
          startIcon={<RemoveIcon height={18} />}
        >
          DELETE
        </Button>
        <Button
          onClick={onDownload}
          className={classes.button}
          variant="outlined"
          startIcon={<DownloadIcon height={18} />}
        >
          DOWNLOAD
        </Button>
      </Hidden>
    </Box>
  );
};

export default MediaActionToolbar;
