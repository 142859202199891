/**
 * Simple wrapper around localStorage
 * Used by react-oidc client
 */

class LocalStorage {
  constructor() {
    return window.localStorage;
  }
}

export default LocalStorage;
