const tabs = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'UPCOMING',
    label: 'Upcoming',
  },
  {
    value: 'FINISHED',
    label: 'Past',
  },
  {
    value: 'all',
    label: 'All',
  },
];

export default tabs;
