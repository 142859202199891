import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardDetails: {
    display: 'flex',
  },
}));

const WelcomeCard = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={3}>
        <div className={classes.cardDetails}>
          <Box>
            <Typography variant="h1">Welcome!</Typography>
            <Typography style={{ marginTop: 20 }}>
              This platform is the place to organize and manage all your live or
              upcoming activations. Use the sidebar to navigate throughout the
              site. We&apos;ll always use this platform to share important
              updates or let you know about new features as our offerings will
              be growing a lot over the next year!
            </Typography>
          </Box>
          <div>
            <img src="/static/images/gallery.svg" />
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default WelcomeCard;
