import React from 'react';
import { Box, Typography } from '@material-ui/core';

const StepSubTitle = ({ children }) => (
  <Box px={3}>
    <Typography variant="subtitle1" color="textSecondary">
      {children}
    </Typography>
  </Box>
);

export default StepSubTitle;
