import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Page from 'components/Page';
import WelcomeCard from './cards/WelcomeCard';
import AnalyticsCard from './cards/AnalyticsCard';
import EventCard from 'components/EventCard';
import SupportCard from './cards/SupportCard';
import { useEffect, useState } from 'react';
import { getEvents } from '../../../api/events';
import { ACTIVE, FINISHED, UPCOMING } from '../../../consts/event-states';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridItem: {
    width: '100%',
  },
  cardDetails: {
    display: 'flex',
  },
}));

const START_TIME_SORT_FIELD = 'start_time';
const END_TIME_SORT_FIELD = 'end_time';

const Dashboard = () => {
  const classes = useStyles();

  const [displayedEvent, setDisplayedEvent] = useState(null);

  useEffect(async () => {
    /* We want to fetch active, upcoming and finished events at once.
    Then we decide what event should be displayed on dashboard.

    Creating requests promises beforehand
    to make API calls in parallel */

    // Fetch active events, sorted with the start time furthest in the past first (to find the first event to start).
    const activeEventsReq = getEvents({
      state: ACTIVE,
      sort: `${START_TIME_SORT_FIELD}`,
    });

    // Fetch upcoming events, sorted with the start time nearest in the future first (to find the next event).
    const upcomingEventsReq = getEvents({
      state: UPCOMING,
      sort: `${START_TIME_SORT_FIELD}`,
    });

    // Fetch recent events, sorted with the events nearest in the past first (to find the most recent event).
    const recentEventsReq = getEvents({
      state: FINISHED,
      sort: `-${END_TIME_SORT_FIELD}`,
    });

    //TODO (andrewhalych) Add error handling
    const { data: upcomingEventsData } = await upcomingEventsReq;
    const { data: activeEventsData } = await activeEventsReq;
    const { data: recentEventsData } = await recentEventsReq;

    /* Show the first event we find, in the following order: 1) An active event, 2) An upcoming event, or 3) A recent
      event. Do not set an event if none are found (in order to display 'No Events' component).
     */
    const events = [
      activeEventsData[0],
      upcomingEventsData[0],
      recentEventsData[0],
    ].filter(Boolean);

    setDisplayedEvent(events[0]);
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Box m={3}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
              <Grid
                container
                direction="column"
                spacing={3}
                alignItems="flex-start"
              >
                <Grid item className={classes.gridItem}>
                  <WelcomeCard />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <EventCard withLabel event={displayedEvent} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12}>
              <Grid
                container
                direction="column"
                spacing={3}
                alignItems="flex-start"
              >
                <Grid item className={classes.gridItem}>
                  <AnalyticsCard />
                </Grid>
                <Grid item className={classes.gridItem}>
                  <SupportCard />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default Dashboard;
