import { Button, Link, makeStyles } from '@material-ui/core';
import { HelpCircle } from 'react-feather';

const useStyles = makeStyles(() => ({
  helpButton: {
    marginRight: '12px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const EXTERNAL_RESOURCE_URL =
  'https://help.snapbar.com/en/articles/6202236-embed-code-support';

const HelpButton = () => {
  const classes = useStyles();

  return (
    <Button
      className={classes.helpButton}
      variant="contained"
      size="small"
      component={Link}
      target="_blank"
      href={EXTERNAL_RESOURCE_URL}
      startIcon={<HelpCircle height={16} width={16} />}
    >
      Help
    </Button>
  );
};

export default HelpButton;
