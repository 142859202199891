import PropTypes from 'prop-types';
import { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Box, Button, Popover, Typography } from '@material-ui/core';
import { Copy as CopyIcon } from 'react-feather';

const CopyButton = ({ copyContent }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    copy(copyContent);
    setAnchorEl(event.currentTarget);
    // Automatically closing popover
    setTimeout(handleClose, 600);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'copy-popover' : undefined;

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleClick}
        startIcon={<CopyIcon height={16} width={16} />}
      >
        Copy
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box p={1}>
          <Typography variant="subtitle2" color="textSecondary">
            Snippet copied!
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

CopyButton.propTypes = {
  copyContent: PropTypes.string,
};

export default CopyButton;
