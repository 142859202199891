const authClientId = process.env.REACT_APP_AUTH_CLIENT_ID;
const authBaseUrl = process.env.REACT_APP_AUTH_ENDPOINT;
const appBaseUrl = window.location.origin;

const oauth2Configuration = {
  automaticSilentRenew: true,
  client_id: authClientId,
  redirect_uri: `${appBaseUrl}/oauthCallback`,
  response_type: 'code',
  scope: 'openid profile',
  authority: authBaseUrl,
  silent_redirect_uri: `${appBaseUrl}/authCallbackSilent`,
  loadUserInfo: false,
  post_logout_redirect_uri: appBaseUrl,
};

export default oauth2Configuration;
