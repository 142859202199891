import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import Page from 'components/Page';
import CopyButton from 'components/CopyButton';
import HelpButton from 'components/HelpButton';
import Header from './Header';
import EventContext from 'contexts/event';
import { getGallery } from 'api/gallery';
import { getCollection } from 'api/collection';
import { getVBoothEmbedCode } from 'helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  card: {
    width: '100%',
  },
  cardRow: {
    display: 'flex',
    height: '56px',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  verticalDivider: {
    height: '100%',
    width: '1px',
    backgroundColor: '#eee',
  },
}));

const VirtualBoothOverview = () => {
  const classes = useStyles();
  const [collection, setCollection] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [usedCount, setUsedCount] = useState(0);
  const { event, updateContextValues } = useContext(EventContext);

  useEffect(async () => {
    if (!event) {
      return;
    }
    updateContextValues({ loading: true });

    const galleryRes = getGallery(event?.galleries[0]);
    const collectionRes = getCollection(event?.collection);

    const { data: galleryData } = await galleryRes;
    const { data: collectionData } = await collectionRes;

    setCollection(collectionData);
    setGallery(galleryData);
    setUsedCount(event?.credits_spent || 0);

    updateContextValues({ loading: false });
  }, [event]);

  return (
    <Page className={classes.root} title="Booth Overview">
      <Box
        className={classes.contentContainer}
        mx={5}
        my={{ xs: 1, md: 2 }}
        flex="1 auto"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <Box mt={5} flex={1}>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item lg={8} sm={12}>
              <Paper>
                <Box height="200px" display="flex" flexDirection="column">
                  <Box px={3} className={classes.cardRow}>
                    <Typography variant="h5">Booth</Typography>
                  </Box>
                  <Box
                    px={3}
                    py={2}
                    flex={1}
                    display="flex"
                    alignItems="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item lg={6} sm={6}>
                        <TextField
                          fullWidth
                          key={gallery?.capture_url}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          label="URL"
                          defaultValue={gallery?.capture_url}
                        />
                      </Grid>
                      <Grid item lg={6} sm={6}>
                        <TextField
                          key={collection?.url}
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="outlined"
                          label="Collection URL"
                          defaultValue={collection?.url}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Paper>
                <Box height="200px" display="flex" flexDirection="column">
                  <Box
                    px={3}
                    className={classes.cardRow}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Credits</Typography>
                  </Box>
                  <Box
                    borderBottom="1px solid #eee"
                    display="flex"
                    px={4}
                    py={3}
                    mb={4}
                    alignItems="stretch"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      flex="1 auto"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h3">{usedCount}</Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        gutterBottom
                      >
                        USED
                      </Typography>
                    </Box>
                    <div className={classes.verticalDivider} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      flex="1 auto"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h3">
                        {event?.credits_purchased}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="textSecondary"
                        gutterBottom
                      >
                        PURCHASED
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <Paper>
                <Box pb={3} display="flex" flexDirection="column">
                  <Box
                    px={3}
                    className={classes.cardRow}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Booth Embed Code</Typography>
                  </Box>
                  <Box px={3} pt={3} flex={1}>
                    <TextField
                      type="textarea"
                      variant="outlined"
                      fullWidth
                      key={gallery}
                      rows={3}
                      multiline
                      disabled={!gallery?.embeddable}
                      rowsMax={4}
                      InputProps={{
                        readOnly: true,
                        style: {
                          borderRadius: 4,
                          backgroundColor: '#f9f9fb',
                          fontSize: '0.875rem',
                          fontFamily: gallery?.embeddable
                            ? 'monospace'
                            : '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      defaultValue={getVBoothEmbedCode(gallery)}
                    />
                  </Box>
                  {gallery?.embeddable && (
                    <Box
                      px={3}
                      py={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                    >
                      <HelpButton />
                      <CopyButton copyContent={getVBoothEmbedCode(gallery)} />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default VirtualBoothOverview;
