import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

import { virtualize } from 'react-swipeable-views-utils';
import { findIndex } from 'lodash';
import {
  Download as DownloadIcon,
  EyeOff as HideIcon,
  Trash2 as TrashIcon,
  Delete as DeleteIcon,
  CheckCircle as ApproveIcon,
} from 'react-feather';
import clsx from 'clsx';
import { getMediaVariant } from '../../helpers';
import moment from 'moment';
import { downloadMedia } from '../../api/media';
import { ACCEPTED, DENIED, PENDING } from '../../consts/moderation-decisions';
import MediaRenderer from '../MediaRenderer';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: `1304 !important`,
    color: '#fff',
  },
  lightBoxContainer: {
    zIndex: 10,
    backgroundColor: '#fff',
    height: '100%',
  },
  closeBtn: {
    width: 40,
    marginLeft: 'auto',
    justifySelf: 'flex-end',
  },
  swipable: {
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));

const LightboxMobile = ({
  moderation,
  selectedMediaItem = {},
  media,
  navNext,
  navPrev,
  close,
  onHide,
  onDelete,
  onAccept,
}) => {
  const classes = useStyles();
  const selectedIdx = findIndex(media, { id: selectedMediaItem.id });
  const [idx, setIdx] = useState(selectedIdx);
  const [isExpanded, setIsExpanded] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (idx, indexLatest) => {
    setIdx(idx);

    if (idx > indexLatest && !!navNext) {
      navNext();
    }
    if (idx < indexLatest && !!navPrev) {
      navPrev();
    }
  };

  const postAction = () => {
    if (navNext) {
      navNext();
    } else {
      close();
    }
  };

  // We need this effect executed so
  // SwipableViews correctly updates current image
  useEffect(() => {
    setIdx(selectedIdx);
  }, [selectedIdx]);

  // Defined inside component to share data
  const LightBox = (params) => {
    const { index, key } = params;

    const item = media[index];

    if (!item) {
      return null;
    }

    const mediaVariant = getMediaVariant(selectedMediaItem, 'original');
    const { filename, update_time } = mediaVariant;

    return (
      <Box p={1.5} key={key} display="flex" flexDirection="column">
        <IconButton className={classes.closeBtn} onClick={close} size="medium">
          <Close fontSize="large" />
        </IconButton>
        <Box position="relative">
          <MediaRenderer
            mediaVariant={mediaVariant}
            media={selectedMediaItem}
            muted={false}
            loop={false}
            playing={true}
            controls
            responsive={false}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" py={1.5}>
          <span>{filename}</span>
          <span>{moment(update_time).format('MM/DD/YYYY')}</span>
        </Box>
        <Box
          className={clsx({ [classes.hidden]: isExpanded })}
          display="flex"
          justifyContent="space-around"
        >
          {moderation === PENDING && (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={async () => {
                  onAccept(item);
                  postAction();
                }}
                startIcon={matches ? <ApproveIcon height={20} /> : null}
              >
                Approve
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={async () => {
                  await onHide(item);
                  postAction();
                }}
                startIcon={matches ? <HideIcon height={20} /> : null}
              >
                Hide
              </Button>
            </>
          )}
          {moderation === DENIED && (
            <Button
              color="secondary"
              variant="contained"
              onClick={async () => {
                onAccept(item);
                postAction();
              }}
              startIcon={matches ? <ApproveIcon height={20} /> : null}
            >
              Approve
            </Button>
          )}
          {moderation === ACCEPTED && (
            <Button
              color="secondary"
              variant="contained"
              onClick={async () => {
                await onHide(item);
                postAction();
              }}
              startIcon={matches ? <HideIcon height={20} /> : null}
            >
              Hide
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              downloadMedia(item);
            }}
            startIcon={matches ? <DownloadIcon height={20} /> : null}
          >
            Download
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setIsExpanded(true);
            }}
            startIcon={matches ? <TrashIcon height={20} /> : null}
          >
            Delete
          </Button>
        </Box>
        <Box className={clsx({ [classes.hidden]: !isExpanded })} py={2}>
          <Typography variant="h3" align="center">
            Are you sure?
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" align="center">
            Do you really want to permantely delete this photo? You can not undo
            this action.
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-around">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                setIsExpanded(false);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={async () => {
                setIsExpanded(false);
                onDelete(item);
                postAction();
              }}
              startIcon={<DeleteIcon height={20} />}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      fullScreen
      className={classes.dialog}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box className={classes.lightBoxContainer}>
        <VirtualizeSwipeableViews
          resistance
          index={idx}
          enableMouseEvents
          className={classes.swipable}
          containerStyle={{
            height: '100%',
          }}
          slideCount={media.length}
          overscanSlideAfter={2}
          overscanSlideBefore={2}
          slideRenderer={LightBox}
          onChangeIndex={handleChange}
        />
      </Box>
    </Dialog>
  );
};

export default LightboxMobile;
