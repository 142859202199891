import UserDashboard from 'containers/dashboard/user';
import { isAdminUser } from 'helpers';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { Redirect } from 'react-router-dom';
import useGetEvents from '../../hooks/useGetEvents';

const Dashboard = () => {
  const { oidcUser } = useReactOidc();

  useGetEvents();

  if (isAdminUser(oidcUser)) {
    return <Redirect to="/events" />;
  }

  return <UserDashboard />;
};

export default Dashboard;
