import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import {
  APPROVED_TAB,
  HIDDEN_TAB,
  LATEST_TAB,
  NEED_APPROVAL_TAB,
} from '../../consts/moderation-tabs';
import { ALLOWED_BY_DEFAULT, MUST_REVIEW } from '../../consts/moderation-types';
import { getModerationLabel } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    backgroundColor: '#F9F9FA',
    border: '1px solid #DEE7E7',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const generateEmptyStateLabels = ({ selectedTab, moderation, query }) => {
  const tabValue = selectedTab.value;
  const labels = { label: '', stateDescription: '', buttonLabel: '' };
  if (query) {
    labels.label = 'No Photos Found';
    labels.stateDescription = `
      There are no photos matching "${query}" in the ${getModerationLabel(
      moderation,
      selectedTab.moderationDecision,
    )} tab.
     Try a different query or switch to another tab.
    `;

    return labels;
  }

  switch (tabValue) {
    case NEED_APPROVAL_TAB:
    case LATEST_TAB: {
      if (moderation === MUST_REVIEW) {
        labels.label = 'No New Media';
        labels.stateDescription = `All media have been approved. View the 'Approved' tab to see your gallery.`;
        labels.buttonLabel = 'View Approved';
      }
      if (moderation === ALLOWED_BY_DEFAULT) {
        labels.label = 'No Media Added Yet';
        labels.stateDescription = `Uploaded media will appear on this tab.`;
      }
      break;
    }
    case APPROVED_TAB:
      labels.label = 'No Approved Media';
      labels.stateDescription = `You haven't approved any media. Check the 'Needs Approval' tab for media waiting on approval.`;
      labels.buttonLabel = 'Approve Media';
      break;
    case HIDDEN_TAB:
      labels.label = 'No Hidden Media';
      labels.stateDescription = `Media hidden from the gallery will appear here.`;
      break;
  }

  return labels;
};

const NoMedia = ({ moderation, noMediaAction, selectedTab, query }) => {
  const classes = useStyles();

  const { label, stateDescription, buttonLabel } = generateEmptyStateLabels({
    selectedTab,
    moderation,
    query,
  });

  return (
    <Box
      className={classes.contentContainer}
      p={5}
      alignSelf="center"
      justifySelf="center"
      mx="auto"
      width="500px"
    >
      <Typography variant="h3" align="center" gutterBottom>
        {label}
      </Typography>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        align="center"
        display="block"
        style={{ whiteSpace: 'pre-wrap' }}
        gutterBottom
      >
        {stateDescription}
      </Typography>
      {!!buttonLabel && (
        <Box display="flex" justifyContent="center" mt={5}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              noMediaAction();
            }}
          >
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default NoMedia;
