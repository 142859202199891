import React from 'react';
import { Box, Divider, Grid, TextField } from '@material-ui/core';
import { getDataValidationErrors, isDataValid } from '../validations';

const VirtualBooth = ({
  checkValidation,
  event,
  gallery,
  onChange,
  validations,
}) => (
  <Box>
    <Grid container direction="row" spacing={2}>
      <Grid item lg={5} md={6} xs={12}>
        <Box p={3} pr={0}>
          <Grid container spacing={2} direction="column">
            <Grid item md={12}>
              <TextField
                color="secondary"
                error={!isDataValid('event.credits_purchased', validations)}
                helperText={
                  getDataValidationErrors(
                    'event.credits_purchased',
                    validations,
                  )[0]
                }
                fullWidth
                name="credits_purchased"
                type="number"
                label="Credits"
                variant="outlined"
                value={event.credits_purchased || ''}
                onBlur={(event) => {
                  checkValidation(
                    'event.credits_purchased',
                    event.target.value,
                  );
                }}
                onChange={(event) => {
                  checkValidation(
                    'event.credits_purchased',
                    event.target.value,
                  );
                  onChange(event.target.name, event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item lg={5} md={6} xs={12}>
        <Box p={3} pr={0}>
          <TextField
            color="secondary"
            error={!isDataValid('gallery.capture_url', validations)}
            helperText={
              getDataValidationErrors('gallery.capture_url', validations)[0]
            }
            fullWidth
            name="booth-url"
            label="URL"
            variant="outlined"
            value={gallery.capture_url}
            onBlur={(event) => {
              checkValidation('gallery.capture_url', event.target.value);
            }}
            onChange={(event) => {
              checkValidation('gallery.capture_url', event.target.value);
              onChange(event.target.name, event.target.value.trim());
            }}
          />
        </Box>
      </Grid>
    </Grid>
    <Divider variant="middle" />
  </Box>
);

export default VirtualBooth;
