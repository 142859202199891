import KeenClient from './KeenClient';

// Define a start datetime for Keen queries. We want to query all-time data, for which the start of 2022 permits.
const START_YEAR = 2022;
const START_MONTH = 0;
const START_DAY = 1;
const START_DATE = new Date(
  Date.UTC(START_YEAR, START_MONTH, START_DAY),
).toISOString();
const CURRENT_DATE = new Date().toISOString();
const EVENT_TYPE = 'Final Share Screen Viewed';
const CONNECTING_KEY = 'hub_collection_url';
const ANALYSIS_TYPE = 'count_unique';

/**
 * Function returns a Promise that resolves to the count of events, that represents the total amount of 'credits used'
 * for billing purposes.
 *
 * @param url
 * @param endDate
 * @returns Promise
 */
export function getSubmittedMediaCount(url, endDate = CURRENT_DATE) {
  return KeenClient.query({
    analysisType: ANALYSIS_TYPE,
    eventCollection: EVENT_TYPE,
    target_property: 'uuid',
    timeframe: {
      start: START_DATE,
      end: endDate,
    },
    filters: [
      {
        propertyName: CONNECTING_KEY,
        operator: 'eq', // Equal to aka an exact match
        propertyValue: url,
      },
    ],
  }).catch((error) => {
    console.log(error);
    return error;
  });
}
