import React from 'react';
import propTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  mediaWrapper: {
    flex: '1 auto',
    justifyContent: 'center',
    display: 'flex',
  },
  media: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#f9f9fb',
    '& .media-cover': {
      position: 'absolute',
      height: '100%',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      display: 'none',
      alignContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.55)',
    },
    '&:hover .media-cover': {
      display: 'flex',
      cursor: 'pointer',
    },
  },
  player: {
    top: 0,
    left: 0,
  },
}));

const MediaRenderer = ({
  mediaVariant,
  media = {},
  loop,
  controls,
  muted,
  volume,
  playing,
  responsive,
}) => {
  const classes = useStyles();
  let mediaURL;

  const {
    details: { height, width },
  } = mediaVariant;
  const { tags = [] } = media;

  const isPhoto = tags.includes('_photo');

  if (isPhoto) {
    mediaURL = mediaVariant.serving_url.replace(
      'image/upload',
      'image/upload/c_scale,w_600,q_70',
    );
  } else {
    mediaURL = mediaVariant.serving_url;
  }
  return (
    <div className={classes.mediaWrapper}>
      {isPhoto && (
        <img alt="Snapbar" src={mediaURL} className={classes.media} />
      )}
      {!isPhoto && (
        <div
          style={{
            paddingTop: responsive
              ? `${100 / (Math.max(width, height) / Math.min(height, width))}%`
              : undefined,
          }}
        >
          <ReactPlayer
            className={classes.player}
            playing={playing}
            muted={muted}
            loop={loop}
            volume={volume}
            controls={controls}
            playsinline
            width="100%"
            height="100%"
            style={{
              backgroundColor: '#000',
              position: responsive ? 'absolute' : 'static',
            }}
            url={mediaURL}
          />
        </div>
      )}
    </div>
  );
};

MediaRenderer.propTypes = {
  controls: propTypes.bool,
  loop: propTypes.bool,
  mediaVariant: propTypes.object,
  media: propTypes.object,
  muted: propTypes.bool,
  playing: propTypes.bool,
  volume: propTypes.bool,
  responsive: propTypes.bool,
};

export default MediaRenderer;
