import React from 'react';
import { Box, Typography } from '@material-ui/core';

const StepTitle = ({ children }) => (
  <Box p={3} pb={0}>
    <Typography variant="h3">{children}</Typography>
  </Box>
);

export default StepTitle;
