import axios from 'axios';

/**
 * @typedef {Object} RequestResult
 * @property {Object} response - The original axios response
 * @property {number} status - HTTP status of response
 * @property {Object} data - The object with data from back-end, populated if request was successful.
 * @property {Object} error - The error info, populated if request failed.
 */

/**
 * Helper for invoking get requests.
 * @param {URL|String} endpoint - Either absolute path to resource or relative, base is baseURL defined in setupDAL fn.
 * @param {Object} [query] - Key-value dictionary serialized into query params.
 * @param {Object} [requestConfig] - Other configurations passed to axios. See https://github.com/axios/axios#request-config for detailed info.
 * @returns {RequestResult} - result of api call.
 */
export const get = async (endpoint, query, requestConfig) => {
  if (!endpoint) throw new Error('endpoint is required');

  try {
    const response = await axios.get(endpoint, {
      params: query,
      ...requestConfig,
    });
    return {
      response,
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error(error);
    return { status: error.status, error };
  }
};

/**
 * Helper for invoking post requests.
 * @param {String} endpoint - Either absolute path to resource or relative, base is baseURL defined in setupDAL fn.
 * @param {Object} data - Request payload.
 * @param {Object} [requestConfig] - Other configurations passed to axios. See https://github.com/axios/axios#request-config for detailed info.
 * @returns {RequestResult} - result of api call.
 */
export const post = async (endpoint, data, requestConfig) => {
  if (!endpoint) throw new Error('endpoint is required');

  try {
    const response = await axios.post(endpoint, data, requestConfig);
    return { response, status: response.status, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: error.status, error };
  }
};

/**
 * Helper for invoking put requests.
 * @param {String|URL} endpoint - Either absolute path to resource or relative, base is baseURL defined in setupDAL fn.
 * @param {Object} data - Request payload.
 * @param {Object} [requestConfig] - Other configurations passed to axios. See https://github.com/axios/axios#request-config for detailed info.
 * @returns {RequestResult} - result of api call.
 */
export const put = async (endpoint, data, requestConfig) => {
  if (!endpoint) throw new Error('endpoint is required');

  try {
    const response = await axios.put(endpoint, data, requestConfig);
    return { response, status: response.status, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: error.status, error };
  }
};

/**
 * Helper for invoking patch requests.
 * @param {String|URL} endpoint - Either absolute path to resource or relative, base is baseURL defined in setupDAL fn.
 * @param {Object} data - Request payload.
 * @param {Object} [requestConfig] - Other configurations passed to axios. See https://github.com/axios/axios#request-config for detailed info.
 * @returns {RequestResult} - result of api call.
 */
export const patch = async (endpoint, data, requestConfig) => {
  if (!endpoint) throw new Error('endpoint is required');

  try {
    const response = await axios.patch(endpoint, data, requestConfig);
    return { response, status: response.status, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: error.status, error };
  }
};

/**
 * Helper for invoking delete requests.
 * @param {String} endpoint - Either absolute path to resource or relative, base is baseURL defined in setupDAL fn.
 * @param {Object} [data] - Request payload.
 * @returns {RequestResult} - result of api call.
 */
export const del = async (endpoint, data) => {
  if (!endpoint) throw new Error('endpoint is required');

  try {
    const response = await axios.delete(endpoint, data);
    return { response, status: response.status, data: response.data };
  } catch (error) {
    console.error(error);
    return { status: error.status, error };
  }
};
