import { del, get, patch } from 'api/api-helper';
import download from 'downloadjs';
import { getMediaVariant } from '../helpers';
/**
 * @typedef {Object} PhotoPageQuery
 * @property {Number} page - Page cursor index.
 * @property {Number} page_size - Amount of photos to be returned.
 * @property {Date} [date] - Date to filter photos by.
 * @property {String} [sort] - Either 'asc' or 'desc' string sort direction
 */

/**
 * Gallery resource helper to request gallery info.
 * @param {String} photosPageUrl - path to page of photos
 * @param {PhotoPageQuery} query - config for photos page
 * @returns {RequestResult} - result of api call.
 */
export const getMediaPage = async (photosPageUrl, query) => {
  return get(photosPageUrl, query);
};

/**
 * Gallery resource helper to request photo info.
 * @param {String} photoURL - path photo resource
 * @returns {RequestResult} - result of api call.
 */
export const getMedia = async (photoURL) => {
  return get(photoURL);
};

export const updateMediaItemData = async (mediaURL, data) => {
  return patch(mediaURL, data);
};

export const deleteMedia = async (mediaURL) => {
  return del(mediaURL);
};

export const downloadMedia = async (media) => {
  const mediaVariant = getMediaVariant(media, 'original');

  const x = new XMLHttpRequest();
  x.open('GET', mediaVariant.serving_url, true);
  x.responseType = 'blob';
  x.onload = function (e) {
    download(e.target.response, mediaVariant.filename, mediaVariant.mime_type);
  };
  x.send();
};
