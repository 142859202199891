import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
  Box,
  Hidden,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import EventContext from '../../contexts/event';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();
  const { event } = useContext(EventContext);

  return (
    <Box
      className={clsx(classes.root, className)}
      {...rest}
      display="flex"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" mr="auto">
        <Hidden xsDown>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/" component={RouterLink}>
              Events
            </Link>
            <Typography color="textPrimary">{event?.display_name}</Typography>
          </Breadcrumbs>
        </Hidden>
        <Typography variant="h3" color="textPrimary">
          Analytics
        </Typography>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
