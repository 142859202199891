import React, { useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import {
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
  Box,
  Button,
  Grid,
  Hidden,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { isAdminUser, isClientUser } from '../../helpers';
import EventContext from '../../contexts/event';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const useStyles = makeStyles((theme) => ({
  root: {},
  infoAlert: {
    marginTop: theme.spacing(2),
    marginBottom: -theme.spacing(2),
  },
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const { eventId } = useParams();
  const { event } = useContext(EventContext);

  return (
    <>
      <Box
        className={clsx(classes.root, className)}
        {...rest}
        display="flex"
        alignItems="baseline"
      >
        <Box display="flex" flexDirection="column" mr="auto">
          <Hidden xsDown>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link color="inherit" to="/" component={RouterLink}>
                Events
              </Link>
              <Typography color="textPrimary">{event?.display_name}</Typography>
            </Breadcrumbs>
          </Hidden>
          <Typography variant="h3" color="textPrimary">
            Gallery Overview
          </Typography>
        </Box>
        {isAdminUser(oidcUser) && (
          <Button
            component={Link}
            href={`/events/${eventId}/edit`}
            variant="contained"
            color="secondary"
            size="medium"
          >
            Edit
          </Button>
        )}
      </Box>
      {isClientUser(oidcUser) && (
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <Alert severity="info" className={classes.infoAlert}>
              Please contact your Account Manager to make changes.
            </Alert>
          </Grid>
        </Grid>
      )}
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
