import {Box, Container, makeStyles, Typography} from '@material-ui/core';
import Page from 'components/Page';
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
  gridItem: {
    width: '100%',
  },
  cardDetails: {
    display: 'flex',
  },
  iframeStyle: {
    width: '100%',
    height: 'calc(100vh - 190px)',
    border: '1px solid #DEE7E7',
    borderRadius: 4,
    backgroundColor: '#ffffff',
    marginTop: 15
  }
}));

const Resources = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Resources">
      <Box m={2}>
        <Container maxWidth="lg">
          <Typography variant="h3" color="textPrimary">
            Resources
          </Typography>
          <iframe src="https://www.snapbar.co/dashboard-resources" className={classes.iframeStyle}></iframe>
        </Container>
      </Box>
    </Page>
  );
};

export default Resources;
