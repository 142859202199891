import React, { useEffect, useState } from 'react';
import { getDataValidationErrors, isDataValid } from '../validations';
import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import { getAccounts } from '../../../api/accounts';

const useStyles = makeStyles((theme) => ({
  gridRoot: {
    width: '100%',
  },
}));

const Final = ({
  checkValidation,
  accounts,
  event,
  onChange,
  saveEvent,
  validations,
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(async () => {
    if (!query || query.length <= 2) {
      setSearchOptions([]);
      return;
    }

    if (accounts.find((item) => item.display_name === query)) {
      return;
    }

    setIsSearching(true);
    const { data, error } = await getAccounts({
      q: query,
      per_page: 2500,
    });

    if (error) {
      setSearchOptions([]);
    } else {
      setSearchOptions(data);
    }
    setIsSearching(false);
  }, [query]);

  const getSelectedAccount = () => {
    if (event?.account?.id) {
      return event.account;
    }

    return (
      accounts.find((item) => {
        return item.url === event?.account;
      }) || ''
    );
  };

  const handleSearch = debounce((event, value) => {
    setQuery(value.trimLeft());
  }, 180);

  const handleBlur = (blurEvent) => {
    const account = accounts.find((item) => {
      return item.display_name === blurEvent.target.value;
    });

    // Checking event.account because this scenario
    // happens when user searched for non-existing acc and value does not change but blur evt is fired
    checkValidation('event.account', account?.url || event?.account?.url || '');
  };

  return (
    <Box>
      <Grid
        className={classes.gridRoot}
        container
        item
        direction="column"
        lg={5}
        md={6}
        xs={12}
      >
        <Grid item md={12}>
          <Box p={3} pr={0} pl={5} display="flex" flexDirection="column">
            <Autocomplete
              name="account"
              disableClearable={false}
              options={!!searchOptions.length ? searchOptions : accounts}
              getOptionLabel={(item) => {
                return item?.display_name || '';
              }}
              getOptionSelected={(option, value) => {
                return option.id === value?.id;
              }}
              onBlur={handleBlur}
              value={getSelectedAccount()}
              onInputChange={handleSearch}
              loading={isSearching}
              loadingText={'Searching Accounts...'}
              onChange={(event, value) => {
                checkValidation('event.account', value?.url || '');
                // Not using event as it fires on menu item instead of combobox
                onChange('account', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  color="secondary"
                  label="Find Account"
                  error={!isDataValid('event.account', validations)}
                  helperText={
                    getDataValidationErrors('event.account', validations)[0]
                  }
                  variant="outlined"
                  InputProps={{ ...params.InputProps, type: 'text' }}
                />
              )}
            />
            <Box mt={4}>
              <Button color="secondary" variant="contained" onClick={saveEvent}>
                {event.id ? 'SAVE EVENT' : 'CREATE EVENT'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Final;
