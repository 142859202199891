import { ALLOWED_BY_DEFAULT } from '../../consts/moderation-types';

export const initEventState = {
  account: '',
  manager: null,
  collection: null,
  galleries: [],
  state: 'UPCOMING',
  display_name: '',
  credits_purchased: '',
  timezone: '',
  start_time: '',
  end_time: '',
  archive_time: null,
};

export const initCollectionState = {
  account: null,
  display_name: '',
  allow_uploads: true,
  moderation: ALLOWED_BY_DEFAULT,
  permissions: {
    create_session: 'ANYONE',
    upload_photo: 'ANYONE',
    view_photo: 'ANYONE',
  },
  limits: {
    max_photos: null,
    max_photos_per_session: null,
  },
};

export const initGalleryState = {
  account: '',
  collection: null,
  enabled: true,
  public_url: '',
  capture_url: '',
  display_name: '',
  message: '',
  primary_color: '#102537',
  secondary_color: '#FFFFFF',
  password: '',
  embeddable: true,
  whitelabeled: false,
  sharing: {
    email_enabled: false,
    sms_enabled: false,
    facebook_enabled: false,
    instagram_enabled: false,
    twitter_enabled: false,
    hashtags: [],
  },
};
