import moment from 'moment';
/*
 * eventReducer is used in `event-creation/index.js` as a Redux like reducer
 * that reacts to certain events and mutates state accordingly.
 */
const eventReducer = (state, action) => {
  switch (action.type) {
    case 'event-name': {
      return {
        ...state,
        event: {
          ...state.event,
          display_name: action.data,
        },
        collection: {
          ...state.collection,
          display_name: action.data,
        },
      };
    }
    case 'start_time': {
      let time = moment(action.data);
      let end_time = state.event.end_time ? moment(state.event.end_time) : null;
      if (state.event.end_time && time.isAfter(end_time)) {
        end_time = null;
      }
      return {
        ...state,
        event: {
          ...state.event,
          end_time,
          [action.type]: time.startOf('day').toISOString(),
        },
      };
    }
    case 'end_time': {
      let time = moment(action.data);
      return {
        ...state,
        event: {
          ...state.event,
          [action.type]: time.endOf('day').toISOString(),
        },
      };
    }
    case 'credits_purchased': {
      return {
        ...state,
        event: {
          ...state.event,
          credits_purchased: action.data,
        },
      };
    }
    case 'gallery_name': {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          display_name: action.data,
        },
      };
    }
    case 'booth-url': {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          capture_url: action.data,
        },
      };
    }
    case 'message': {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          message: action.data,
        },
      };
    }
    case 'moderation': {
      return {
        ...state,
        collection: {
          ...state.collection,
          moderation: action.data,
        },
      };
    }
    case 'embeddable': {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          embeddable: action.data,
        },
      };
    }
    case 'password': {
      return {
        ...state,
        gallery: {
          ...state.gallery,
          password: action.data,
        },
      };
    }
    case 'account': {
      const account = action.data;

      return {
        ...state,
        event: {
          ...state.event,
          account: account?.url || '',
          manager: account?.manager.url,
        },
        gallery: {
          ...state.gallery,
          account: account?.url || '',
        },
        collection: {
          ...state.collection,
          account: account?.url || '',
        },
      };
    }
    case 'collection': {
      const collection = action.data;
      return {
        ...state,
        collection,
        event: {
          ...state.event,
          collection: collection.url,
        },
        gallery: {
          ...state.gallery,
          collection: collection.url,
        },
      };
    }
    case 'gallery': {
      const gallery = action.data;
      return {
        ...state,
        gallery,
        event: {
          ...state.event,
          galleries: [gallery.url],
        },
      };
    }
    case 'event': {
      const event = action.data;
      return {
        ...state,
        event: {
          ...event,
          account: event.account.url,
        },
      };
    }
    case 'finish': {
      return {
        ...state,
        created: action.data,
      };
    }

    default:
      console.warn(
        'Within eventReducer, there is no specific code to handle %s: the state will remain unchanged',
        action.type,
      );
      return state;
  }
};

export default eventReducer;
