import { makeStyles, Modal, Typography } from '@material-ui/core';
import { Info as InfoIcon } from 'react-feather';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: '#f9f9fa',
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
  content: {
    padding: '20px 30px',
    borderRadius: '4px',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    '-webkit-transform': 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    width: '480px',
    height: '340px',
    maxWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #e9e9e9',
  },
  infoIcon: {
    width: 64,
    height: 64,
    position: 'absolute',
    top: -32,
    backgroundColor: '#102638',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    borderRadius: '4px',
  },
  heading: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: theme.spacing(3),
  },
  text: {
    margin: '0 30px',
  },
}));

const UnsupportedBrowserModal = () => {
  const classes = useStyles();

  return (
    <Modal className={classes.backdrop} hideBackdrop open>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.infoIcon}>
            <InfoIcon width={32} height={32} />
          </div>
          <Typography variant="h3" className={classes.heading}>
            Unsupported Web Browser
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.text}
          >
            Internet Explorer is no longer supported. Please try opening this
            link in
            <a
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noreferrer"
            >
              <b> Edge</b>
            </a>
            ,
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noreferrer"
            >
              <b> Chrome </b>
            </a>
            or another modern web browser.
          </Typography>
        </div>
      </div>
    </Modal>
  );
};

export default UnsupportedBrowserModal;
