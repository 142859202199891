import React from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  ALLOWED_BY_DEFAULT,
  MUST_REVIEW,
} from '../../../consts/moderation-types';
import { getDataValidationErrors, isDataValid } from '../validations';

const Gallery = ({
  classes,
  gallery = {},
  collection,
  onChange,
  validations,
  checkValidation,
}) => {
  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item lg={5} md={6} xs={12}>
          <Box p={3} pr={0}>
            <Grid container spacing={2} direction="column">
              <Grid item md={12}>
                <TextField
                  color="secondary"
                  fullWidth
                  name="gallery_name"
                  key={2}
                  error={!isDataValid('gallery.display_name', validations)}
                  helperText={
                    getDataValidationErrors(
                      'gallery.display_name',
                      validations,
                    )[0]
                  }
                  variant="outlined"
                  label="Gallery Heading"
                  value={gallery.display_name}
                  onBlur={(event) => {
                    checkValidation('gallery.display_name', event.target.value);
                  }}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  key={3}
                  color="secondary"
                  className={classes.galleryText}
                  variant="outlined"
                  name="message"
                  fullWidth
                  multiline
                  InputProps={{
                    style: {
                      height: '100%',
                    },
                  }}
                  rows={4}
                  label="Gallery Text"
                  value={gallery.message}
                  onChange={(event) => {
                    onChange(event.target.name, event.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={5} md={6} xs={12}>
          <Box p={3} pr={0} height="100%" display="flex" flexDirection="column">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Password Protection
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Require a password to view the gallery.
            </Typography>
            <FormControlLabel
              className={classes.switchLabel}
              label={
                <Typography variant="body2" color="textSecondary">
                  {!!gallery?.password ? 'Enabled' : 'Disabled'}
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  checked={!!gallery?.password}
                  edge="start"
                  name="password"
                  onChange={(event, checked) => {
                    onChange(
                      event.target.name,
                      checked ? '<SET PASSWORD>' : '',
                    );
                  }}
                />
              }
            />
            <TextField
              style={{ marginTop: 'auto' }}
              fullWidth
              name="password"
              label="Password"
              variant="outlined"
              value={gallery.password}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item lg={4}>
          <Box p={3} display="flex" flexDirection="column">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Manual Approval
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              When enabled, only approved media will appear in the gallery.
            </Typography>
            <FormControlLabel
              className={classes.switchLabel}
              label={
                <Typography variant="body2" color="textSecondary">
                  {collection?.moderation === MUST_REVIEW
                    ? 'Enabled'
                    : 'Disabled'}
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  checked={collection?.moderation === MUST_REVIEW}
                  edge="start"
                  name="moderation"
                  onChange={(event, checked) => {
                    onChange(
                      event.target.name,
                      !checked ? ALLOWED_BY_DEFAULT : MUST_REVIEW,
                    );
                  }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item lg={4}>
          <Box p={3} display="flex" flexDirection="column">
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Embed
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Allow clients the option to embed their gallery.
            </Typography>
            <FormControlLabel
              className={classes.switchLabel}
              label={
                <Typography variant="body2" color="textSecondary">
                  Enabled
                </Typography>
              }
              control={
                <Switch
                  size="small"
                  key={gallery}
                  checked={!!gallery?.embeddable}
                  edge="start"
                  name="embeddable"
                  onChange={(event, checked) => {
                    onChange(event.target.name, checked);
                  }}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Divider variant="middle" />
    </Box>
  );
};

export default Gallery;
