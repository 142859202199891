import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Box
        width={400}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h3"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          Sorry, the event, you are looking for could not be found.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            history.replace('/events');
          }}
          className={classes.button}
        >
          View Events
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
