import { useContext, useState } from 'react';
import KeenContext from 'contexts/keen';

const KeenProvider = (props) => {
  const initialState = useContext(KeenContext);
  const [state, setState] = useState(initialState);

  return (
    <KeenContext.Provider
      value={{
        ...state,
        updateContextValues: (valuesToUpdate, callback) => {
          setState({ ...state, ...valuesToUpdate });

          if (typeof callback === 'function') {
            callback();
          }
        },
      }}
    >
      {props.children}
    </KeenContext.Provider>
  );
};

export default KeenProvider;
