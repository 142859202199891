import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';

import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context';

import oidcConfig from 'api/oAuthConfig';
import LocalStorage from 'helpers/LocalStorage';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthenticationSpinner from './components/AuthenticationSpinner';
import { isInternetExplorerUserAgent } from './helpers';
import UnsupportedBrowserModal from './components/UnsupportedBrowserModal';

const appSignal = new Appsignal({
  key: process.env.REACT_APP_APPSIGNAL_KEY,
});

const FallbackComponent = ({ error }) => {
  console.error(error);

  return <div>Sorry, there was an error.</div>;
};

const logLevel =
  process.env.REACT_APP_ENV === 'production' ? oidcLog.WARN : oidcLog.INFO;

ReactDOM.render(
  isInternetExplorerUserAgent() ? (
    <UnsupportedBrowserModal />
  ) : (
    <React.StrictMode>
      <AuthenticationProvider
        configuration={oidcConfig}
        loggerLevel={logLevel}
        isEnabled
        callbackComponentOverride={AuthenticationSpinner}
        authenticating={AuthenticationSpinner}
        sessionLostComponent={() => {
          return <div>Session lost</div>;
        }}
        notAuthorized={() => {
          return <div>Not Authorized</div>;
        }}
        notAuthenticated={() => {
          return <div>Not Authenticated</div>;
        }}
        UserStore={LocalStorage}
      >
        <ErrorBoundary
          instance={appSignal}
          fallback={(error) => <FallbackComponent error={error} />}
        >
          <App />
        </ErrorBoundary>
      </AuthenticationProvider>
    </React.StrictMode>
  ),
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
