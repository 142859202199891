import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cardDetails: {
    display: 'flex',
  },
  cardDetailsEmail: {
    color: 'initial',
  },
}));

const SupportCard = (props) => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={3}>
        <div className={classes.cardDetails}>
          <Box>
            <Typography variant="h1">Support</Typography>
            <Typography style={{ marginTop: 20 }}>
              If you experience any issues using this platform or your Booth,
              please email{' '}
              <a
                href="mailto:support@thesnapbar.com"
                className={classes.cardDetailsEmail}
              >
                support@thesnapbar.com
              </a>
              .
            </Typography>
          </Box>
          <div>
            <img src="/static/images/support.svg" />
          </div>
        </div>
      </Box>
    </Paper>
  );
};

export default SupportCard;
