import { useContext } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
  Box,
  Button,
  Hidden,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { isAdminUser } from '../../helpers';
import EventContext from '../../contexts/event';
import { useReactOidc } from '@axa-fr/react-oidc-context';

const useStyles = makeStyles((theme) => ({
  root: {},
  pageName: {
    marginTop: theme.spacing(1),
  },
}));

const Header = ({ className, ...rest }) => {
  const { eventId } = useParams();
  const classes = useStyles();
  const { oidcUser } = useReactOidc();
  const { event } = useContext(EventContext);

  return (
    <Box
      className={clsx(classes.root, className)}
      {...rest}
      display="flex"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" mr="auto">
        <Hidden xsDown>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/" component={RouterLink}>
              Platform
            </Link>
            <Typography color="textPrimary">Events</Typography>
          </Breadcrumbs>
        </Hidden>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.pageName}
        >
          {event?.display_name}
        </Typography>
      </Box>
      {isAdminUser(oidcUser) && (
        <Button
          component={Link}
          href={`/events/${eventId}/edit`}
          variant="contained"
          color="secondary"
          size="medium"
        >
          Edit
        </Button>
      )}
    </Box>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
