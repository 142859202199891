import { Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { HelpCircle as HelpIcon } from 'react-feather';

const Info = () => {
  return (
    <Tooltip title="Need help?">
      <IconButton
        color="inherit"
        target="_blank"
        href="https://help.snapbar.com/"
      >
        <SvgIcon fontSize="small">
          <HelpIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
};

export default Info;
