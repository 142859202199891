import PropTypes from 'prop-types';
import { Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import { LogOut as LogOutIcon } from 'react-feather';

const Logout = ({ doLogOut }) => {
  return (
    <Tooltip title="Log Out">
      <IconButton color="inherit" onClick={doLogOut}>
        <SvgIcon fontSize="small">
          <LogOutIcon />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  );
};

Logout.propTypes = {
  doLogOut: PropTypes.func,
};

Logout.defaultProps = {
  doLogOut: () => {},
};

export default Logout;
