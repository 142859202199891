import { useEffect, useContext } from 'react';
import EventContext from 'contexts/event';
import { getEvents } from 'api/events';

/**
 * Custom hook to be used for fetching events info from backend.
 * In process of fetching hook sets isLoading prop that can be used to show spinner.
 */
const useGetEvents = () => {
  const context = useContext(EventContext);

  useEffect(() => {
    context.updateContextValues({ loading: true });

    getEvents().then((apiResponse) => {
      if (apiResponse?.status === 200) {
        const { data, response } = apiResponse;

        context.updateContextValues({
          events: { data, response },
          loading: false,
        });
      } else {
        context.updateContextValues({ loading: false });
      }
    });
  }, []);
};

export default useGetEvents;
