import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Spinner = ({ style }) => (
  <Box
    minHeight="100%"
    width="100%"
    flex={1}
    display="flex"
    alignItems="center"
    style={style}
    justifyContent="center"
  >
    <CircularProgress variant="indeterminate" style={{ color: '#102537' }} />
  </Box>
);

export default Spinner;
