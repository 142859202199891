import { get, patch, post } from 'api/api-helper';

/**
 * Events resource helper to request events info.
 * @returns {RequestResult} - result of api call.
 */
export const getEvents = async (query) => {
  return get(`/events`, query);
};

/**
 * Event resource helper to request event info.
 * @param {Number} eventId - gallery ID
 * @returns {RequestResult} - result of api call.
 */
export const getEvent = async (eventId) => {
  return get(`/events/${eventId}`);
};

export const createEvent = async (eventData) => {
  return post(`/events`, eventData);
};

export const updateEvent = async (eventURL, eventData) => {
  return patch(eventURL, eventData);
};
