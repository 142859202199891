import { get, post, put } from 'api/api-helper';
import { isURL } from 'helpers';

/**
 * Gallery resource helper to request gallery info.
 * @param {Number|URL} galleryId - gallery ID
 * @returns {RequestResult} - result of api call.
 */
export const getGallery = async (galleryId) => {
  return get(isURL(galleryId) ? galleryId : `/galleries/${galleryId}`);
};

/**
 * Gallery resource helper to create new gallery.
 * @param {Object} galleryData - complete gallery data object
 * @returns {RequestResult} - result of api call.
 */
export const createGallery = async (galleryData) => {
  return post(`/galleries`, galleryData);
};

/**
 * Gallery resource helper to update gallery.
 * @param {String} galleryURL - gallery URI
 * @param {Object} galleryData - partial gallery data object
 * @returns {RequestResult} - result of api call.
 */
export const updateGallery = async (galleryURL, galleryData) => {
  return put(galleryURL, galleryData);
};
