import {
  Box,
  Button,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  TablePagination,
  Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import Header from './Header';
import React, { useContext, useEffect, useState } from 'react';
import LightBox from 'components/lightbox';
import { difference, findIndex } from 'lodash';
import DownloadDialog from 'components/dialogs/DownloadDialog';
import DeleteDialog from 'components/dialogs/DeleteDialog';
import TabToolbar from './TabToolbar';
import { ALLOWED_BY_DEFAULT, MUST_REVIEW } from 'consts/moderation-types';
import clsx from 'clsx';
import {
  filterItemsBy,
  findMaxValue,
  getModerationLabel,
  getMediaVariant,
  OK_HTTP_STATUSES,
} from 'helpers';
import MediaActionToolbar from 'containers/moderation/MediaActionToolbar';
import {
  OpenWith as ExpandIcon,
  RefreshOutlined as RefreshIcon,
} from '@material-ui/icons';
import {
  APPROVED_TAB,
  HIDDEN_TAB,
  NEED_APPROVAL_TAB,
  LATEST_TAB,
} from 'consts/moderation-tabs';
import EventContext from 'contexts/event';
import { exportCollection, getCollection } from 'api/collection';
import {
  deleteMedia,
  downloadMedia,
  getMediaPage,
  updateMediaItemData,
} from 'api/media';
import { get } from 'api/api-helper';
import { ACCEPTED, DENIED, PENDING } from 'consts/moderation-decisions';
import useInterval from 'hooks/useInterval';
import qs from 'qs';
import NoMedia from 'components/NoMedia';
import Spinner from 'components/Spinner';
import MediaRenderer from '../../components/MediaRenderer';

const INITIAL_DELAY_MS = 5000;
const MAX_DELAY_MS = 12000;
const DELAY_FACTOR = 1.02;
const MAX_DIRECT_DOWNLOAD_MEDIA_COUNT = 5;
const MEDIA_LOADING_INTERVAL_MS = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  contentBox: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  card: {
    width: '100%',
    '&.selected': {
      boxShadow: `0px 0px 0px 3px ${theme.palette.secondary.main}`,
    },
  },
  cardActions: {
    padding: 0,
  },
  switchLabel: {
    marginLeft: 0,
  },
  button: {
    marginLeft: '12px',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    position: 'relative',
  },
  mediaLoadingLinearIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  media: {
    maxWidth: '100%',
    position: 'relative',
    justifyContent: 'center',
    backgroundColor: '#f9f9fb',
    '& .media-cover': {
      position: 'absolute',
      height: '100%',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.55)',
    },
    '&:hover .media-cover': {
      display: 'flex',
      cursor: 'pointer',
    },
  },
  scrollGrid: {
    flex: '1 auto',
    height: 0,
    overflowX: 'auto',
    marginTop: 8,
    '&.packed': {
      alignContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
}));

const tabs = [
  {
    value: LATEST_TAB,
    label: getModerationLabel(ALLOWED_BY_DEFAULT, ACCEPTED),
    exportLabel: 'Live Gallery',
    moderationType: ALLOWED_BY_DEFAULT,
    moderationDecision: ACCEPTED,
  },
  {
    value: HIDDEN_TAB,
    label: getModerationLabel(ALLOWED_BY_DEFAULT, DENIED),
    exportLabel: 'Hidden Gallery',
    moderationType: ALLOWED_BY_DEFAULT,
    moderationDecision: DENIED,
  },
  {
    value: NEED_APPROVAL_TAB,
    label: getModerationLabel(MUST_REVIEW, PENDING),
    exportLabel: 'Need Approval Gallery',
    moderationType: MUST_REVIEW,
    moderationDecision: PENDING,
  },
  {
    value: APPROVED_TAB,
    label: getModerationLabel(MUST_REVIEW, ACCEPTED),
    exportLabel: 'Approved Gallery',
    moderationType: MUST_REVIEW,
    moderationDecision: ACCEPTED,
  },
  {
    value: HIDDEN_TAB,
    label: getModerationLabel(MUST_REVIEW, DENIED),
    exportLabel: 'Hidden Gallery',
    moderationType: MUST_REVIEW,
    moderationDecision: DENIED,
  },
];

const Moderation = () => {
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [collectionTabs, setCollectionTabs] = useState(
    filterItemsBy(tabs, 'moderationType', ALLOWED_BY_DEFAULT),
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [initialMediaCount, setInitialMediaCount] = useState(totalMediaCount);

  const [isExporting, setIsExporting] = useState(false);
  const [exportResource, setExportResource] = useState(null);

  const [delay, setDelay] = useState(INITIAL_DELAY_MS);
  const [newestTimeStamp, setNewestTimeStamp] = useState(null);
  // We set initial timestamp as we are interested only in updates that happen after page load
  const [initialTimeStamp, setInitialTimeStamp] = useState(
    new Date().toISOString(),
  );
  const [loadedUpdates, setLoadedUpdates] = useState([]);

  // Flag to determine if loading indicator must be shown
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [loadingInterval, setLoadingInterval] = useState(null);

  const [media, setMedia] = useState([]);
  const [collection, setCollection] = useState(null);
  const { event, updateContextValues, loading } = useContext(EventContext);

  useEffect(async () => {
    if (!event || !collection) {
      return;
    }
    setInitialTimeStamp(new Date().toISOString());

    // Clearing selection on search or tab change.
    setSelectedMedia({});
    updateContextValues({ loading: true });
    const selectedTab = collectionTabs.find((tab) => {
      return tab.value === currentTab;
    });

    const mediaRes = await getMediaPage(collection?.media, {
      q: query,
      per_page: pageSize,
      page: page + 1,
      moderation_decision: selectedTab.moderationDecision,
    });

    const { data: mediaData, response } = mediaRes;

    const lastUpdateTime = findMaxValue(mediaData, 'update_time');

    setNewestTimeStamp(lastUpdateTime);

    setTotalMediaCount(response.headers['x-total-count'] - 0);
    setInitialMediaCount(mediaData.length);
    setMedia(mediaData);

    updateContextValues({ loading: false });

    setLoadingInterval(MEDIA_LOADING_INTERVAL_MS);
    setIsMediaLoading(true);
  }, [collection, currentTab, page, pageSize, query]);

  useEffect(async () => {
    if (!event) {
      return;
    }
    updateContextValues({ loading: true });
    const collectionRes = await getCollection(event?.collection);

    const { data: collectionData } = collectionRes;

    const filteredTabs = filterItemsBy(
      tabs,
      'moderationType',
      collectionData?.moderation || ALLOWED_BY_DEFAULT,
    );
    setCurrentTab(filteredTabs[0].value);
    setCollectionTabs(filteredTabs);

    setCollection(collectionData);

    updateContextValues({ loading: false });
  }, [event]);

  useEffect(() => {
    const filteredTabs = filterItemsBy(
      tabs,
      'moderationType',
      collection?.moderation || ALLOWED_BY_DEFAULT,
    );
    setCurrentTab(filteredTabs[0].value);
  }, [collection]);

  const loadMedia = async () => {
    updateContextValues({ loading: true });
    setMedia([]);

    const selectedTab = collectionTabs.find((tab) => {
      return tab.value === currentTab;
    });

    const mediaRes = await getMediaPage(collection?.media, {
      per_page: pageSize,
      page: page + 1,
      moderation_decision: selectedTab.moderationDecision,
    });

    const { data: mediaData, response } = mediaRes;

    setTotalMediaCount(response.headers['x-total-count'] - 0);
    setInitialMediaCount(mediaData.length);
    setMedia(mediaData);
    updateContextValues({ loading: false });

    setLoadingInterval(MEDIA_LOADING_INTERVAL_MS);
    setIsMediaLoading(true);
  };

  const fetchNewMedia = () => {
    if (collection && media.length) {
      return async () => {
        const selectedTab = collectionTabs.find((tab) => {
          return tab.value === currentTab;
        });

        const { data } = await get(collection.media, {
          moderation_decision: selectedTab.moderationDecision,
          update_time__gt:
            newestTimeStamp > initialTimeStamp
              ? newestTimeStamp
              : initialTimeStamp,
        });

        if (data.length) {
          const lastUpdateTime = findMaxValue(data, 'update_time');

          setLoadedUpdates([...loadedUpdates, ...data]);

          setNewestTimeStamp(lastUpdateTime);
          setDelay(INITIAL_DELAY_MS);
        } else {
          setDelay(delay * DELAY_FACTOR);
        }
      };
    }
    return () => {};
  };

  const navigateFromEmptyState = () => {
    switch (selectedTab.value) {
      case NEED_APPROVAL_TAB: {
        handleTabChange(APPROVED_TAB);
        return;
      }
      case APPROVED_TAB: {
        handleTabChange(NEED_APPROVAL_TAB);
        return;
      }
    }
  };

  useInterval(
    fetchNewMedia(),
    newestTimeStamp ? Math.min(delay, MAX_DELAY_MS) : null,
  );

  // Interval that checks is all the media were loaded, decoded and rendered
  useInterval(() => {
    const mediaElements = Array.from(
      document.querySelectorAll('.media-grid img'),
    );

    const completedMedia = mediaElements
      .filter((mediaItem) => {
        // We ignore media without src
        return mediaItem.getAttribute('src') != undefined;
      })
      .map((mediaItem) => {
        return mediaItem.complete && mediaItem.naturalHeight !== 0;
      });

    // If all media available, we stop loading interval.
    if (completedMedia.every(Boolean)) {
      setLoadingInterval(null);
      setIsMediaLoading(false);
    }
  }, loadingInterval);

  // Helper that returns list of selected media IDs
  const getSelectedMediaIds = () => {
    const selectedMediaCount = Object.keys(selectedMedia).length;

    let ids = [];
    if (isSelectedAll) {
      if (selectedMediaCount) {
        ids = difference(
          media.map((item) => item.id),
          Object.keys(selectedMedia),
        );
      } else {
        ids = media.map((item) => item.id);
      }
    } else {
      ids = Object.keys(selectedMedia);
    }

    return ids;
  };

  const exportMedia = async (fullExport = true) => {
    if (isExporting) {
      return;
    }

    setIsExporting(true);

    let query;
    const selectedTab = collectionTabs.find((tab) => {
      return tab.value === currentTab;
    });

    if (!fullExport) {
      query = {
        moderation_decision: selectedTab.moderationDecision,
      };
      query = {
        ...query,
        ids: getSelectedMediaIds(),
      };
    }

    const exportResponse = await exportCollection(collection['exports'], {
      preset: 'ZIP_FLAT',
      query: qs.stringify(query, {
        arrayFormat: 'comma',
        encodeValuesOnly: true,
        addQueryPrefix: true,
      }),
    });
    const { data, error, status } = exportResponse;

    if (!OK_HTTP_STATUSES.includes(status)) {
      console.error(error);
    }

    setExportResource(data);
  };

  const isMediaSelected = (mediaItemId) => {
    if (isSelectedAll) {
      return !selectedMedia[mediaItemId];
    } else {
      return !!selectedMedia[mediaItemId];
    }
  };

  const selectAllMedia = () => {
    setSelectedMedia({});
    setSelectedAll(!isSelectedAll);
  };

  const selectedMediaItem = media.find((item) => {
    return item.id === selectedMediaId;
  });

  const handlePageChange = (ev, nextPage) => {
    setPage(nextPage);
  };

  const handlePageSizeChange = (event) => {
    setPage(0);
    setPageSize(event.target.value);
  };

  const updateSelectedMedia = async (action) => {
    const mediaIds = getSelectedMediaIds();

    if (!mediaIds.length) {
      return;
    }

    switch (action) {
      case 'hide': {
        await Promise.all(
          mediaIds.map((mediaId) => {
            const mediaItem = media.find((mediaItem) => {
              return mediaItem.id === mediaId;
            });
            return updateMediaItemData(mediaItem.url, {
              moderation_decision: DENIED,
            });
          }),
        );

        setSelectedMedia({});
        if (mediaIds.length === media.length) {
          setPage(0);
          setSelectedAll(false);
        }
        return loadMedia();
      }
      case 'approve': {
        await Promise.all(
          mediaIds.map((mediaId) => {
            const mediaItem = media.find((mediaItem) => {
              return mediaItem.id === mediaId;
            });
            return updateMediaItemData(mediaItem.url, {
              moderation_decision: ACCEPTED,
            });
          }),
        );

        setSelectedMedia({});

        if (mediaIds.length === media.length) {
          setPage(0);
          setSelectedAll(false);
        }

        return loadMedia();
      }
      case 'delete': {
        await Promise.all(
          mediaIds.map((mediaId) => {
            const mediaItem = media.find((mediaItem) => {
              return mediaItem.id === mediaId;
            });
            return deleteMedia(mediaItem.url);
          }),
        );

        setSelectedMedia({});
        setSelectedAll(false);

        return loadMedia();
      }
      case 'download': {
        await Promise.all(
          mediaIds.map((mediaId) => {
            const mediaItem = media.find((mediaItem) => {
              return mediaItem.id === mediaId;
            });
            return downloadMedia(mediaItem);
          }),
        );

        return loadMedia();
      }
    }
  };

  const viewMedia = (mediaId) => {
    setSelectedMediaId(mediaId);
  };

  const selectMedia = (mediaId) => {
    if (selectedMedia[mediaId]) {
      delete selectedMedia[mediaId];
      setSelectedMedia({ ...selectedMedia });
    } else {
      setSelectedMedia({
        ...selectedMedia,
        [mediaId]: true,
      });
    }
  };

  const navigateNext = () => {
    const selectedMediaIdx = findIndex(media, { id: selectedMediaId });
    const nextMedia = media[selectedMediaIdx + 1];
    if (!nextMedia) {
      return null;
    }

    return () => {
      setSelectedMediaId(nextMedia.id);
    };
  };

  const navigatePrev = () => {
    const selectedMediaIdx = findIndex(media, { id: selectedMediaId });
    const prevMedia = media[selectedMediaIdx - 1];
    if (!prevMedia) {
      return null;
    }

    return () => {
      setSelectedMediaId(prevMedia.id);
    };
  };

  const handleTabChange = (tab) => {
    setPage(0);

    setSelectedAll(false);
    setSelectedMedia({});
    setCurrentTab(tab);
  };

  const selectedTab =
    collectionTabs.find((tab) => {
      return tab.value === currentTab;
    }) || tabs[0];
  // We need this default value, because after getting event data,
  // if moderation is different, collection tabs not updated yet.

  const lightboxActionHandlers = {
    onHide: async (mediaItem) => {
      const mediaIdx = media.findIndex((media) => {
        return media.id === mediaItem.id;
      });

      await updateMediaItemData(mediaItem.url, {
        moderation_decision: DENIED,
      });
      // TODO (andrewhalych) think oh ways to refactor and remove code duplication
      // Updating loaded media while lightbox is open
      // for correct navigation
      const updatedMedia = [...media];
      updatedMedia.splice(mediaIdx, 1);
      setSelectedMediaId(media[mediaIdx + 1]?.id);
      setMedia(updatedMedia);
    },
    onDelete: async (selectedMedia) => {
      const mediaIdx = media.findIndex((mediaItem) => {
        return mediaItem.id === selectedMedia.id;
      });

      await deleteMedia(selectedMedia.url);

      // Updating loaded media while lightbox is open
      // for correct navigation
      const updatedMedia = [...media];
      updatedMedia.splice(mediaIdx, 1);
      setSelectedMediaId(media[mediaIdx + 1]?.id);
      setMedia(updatedMedia);
    },
    onAccept: async (selectedMedia) => {
      const mediaIdx = media.findIndex((mediaItem) => {
        return mediaItem.id === selectedMedia.id;
      });

      await updateMediaItemData(selectedMedia.url, {
        moderation_decision: ACCEPTED,
      });

      // Updating loaded media while lightbox is open
      // for correct navigation
      const updatedMedia = [...media];
      updatedMedia.splice(mediaIdx, 1);
      setSelectedMediaId(media[mediaIdx + 1]?.id);
      setMedia(updatedMedia);
    },
  };

  return (
    <Page className={classes.root} title="Gallery Moderation">
      <Box
        className={classes.contentContainer}
        mx={5}
        my={{ xs: 1, md: 2 }}
        flex="1 auto"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <LightBox
          moderation={selectedTab.moderationDecision}
          media={media}
          mediaId={selectedMediaId}
          selectedMediaItem={selectedMediaItem}
          close={() => {
            viewMedia(null);
            // Only load media upon modal close if media have been removed from view, in order to load from next page.
            if (media.length < initialMediaCount) {
              loadMedia();
            }
          }}
          navNext={navigateNext()}
          navPrev={navigatePrev()}
          {...lightboxActionHandlers}
        />
        <DownloadDialog
          open={isExporting}
          close={() => {
            setIsExporting(false);
            setExportResource(null);
          }}
          event={event}
          exportInfo={exportResource}
        />
        <DeleteDialog
          open={showDeleteDialog}
          selectedMediaCount={getSelectedMediaIds().length}
          close={() => {
            setShowDeleteDialog(false);
          }}
          handleDelete={() => {
            updateSelectedMedia('delete');
          }}
        />
        <Box
          className={classes.contentBox}
          mt={5}
          display="flex"
          flex="1 auto"
          width="100%"
        >
          <Paper className={classes.paper}>
            {isMediaLoading && (
              <LinearProgress
                className={classes.mediaLoadingLinearIndicator}
                color="secondary"
              />
            )}
            <Box
              pt={{ xs: 1, md: 3 }}
              px={2}
              display="flex"
              flexDirection="column"
              position="relative"
              height="100%"
            >
              <TabToolbar
                tabs={collectionTabs}
                onTabChange={handleTabChange}
                onExport={exportMedia}
                currentTab={currentTab}
              />
              <Divider />
              {collection?.moderation && (
                <MediaActionToolbar
                  currentTab={currentTab}
                  moderationType={collection?.moderation}
                  allSelected={isSelectedAll}
                  onSearch={(query) => {
                    setPage(0);
                    setQuery(query);
                  }}
                  onSelectAll={selectAllMedia}
                  onApprove={() => {
                    updateSelectedMedia('approve');
                  }}
                  onDelete={() => {
                    const mediaIds = getSelectedMediaIds();

                    if (!mediaIds.length) {
                      return;
                    }
                    setShowDeleteDialog(true);
                  }}
                  onDownload={() => {
                    const mediaIds = getSelectedMediaIds();

                    if (!mediaIds.length) {
                      return;
                    }

                    if (mediaIds.length <= MAX_DIRECT_DOWNLOAD_MEDIA_COUNT) {
                      updateSelectedMedia('download');
                    } else {
                      exportMedia(false);
                    }
                  }}
                  onHide={() => {
                    updateSelectedMedia('hide');
                  }}
                />
              )}
              {!!loadedUpdates.length && (
                <Box
                  minHeight={44}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setLoadedUpdates([]);
                      loadMedia();
                    }}
                    startIcon={<RefreshIcon height={18} />}
                  >
                    Load new items
                  </Button>
                </Box>
              )}
              <Grid
                container
                spacing={2}
                className={clsx('media-grid', classes.scrollGrid, {
                  packed: !!media.length,
                })}
              >
                {loading && <Spinner />}
                {!loading && !media.length && (
                  <NoMedia
                    key={selectedTab + collection?.id}
                    selectedTab={selectedTab}
                    moderation={collection?.moderation}
                    noMediaAction={navigateFromEmptyState}
                    query={query}
                  />
                )}
                {!loading &&
                  !!media.length &&
                  media.map((mediaItem, idx, array) => {
                    const mediaVariant = getMediaVariant(mediaItem, 'original');

                    // TODO andrewhalych Refactor into separate component.
                    return (
                      <Grid
                        item
                        key={mediaItem.id}
                        xs={6}
                        sm={6}
                        md={3}
                        lg={2}
                        xl={2}
                      >
                        <Card
                          className={clsx(classes.card, {
                            selected: isMediaSelected(mediaItem.id),
                          })}
                        >
                          <CardMedia className={classes.media}>
                            {/* If media src is not available we show label*/}
                            {mediaVariant?.serving_url ? (
                              <MediaRenderer
                                mediaVariant={mediaVariant}
                                media={mediaItem}
                                loop
                                controls={false}
                                muted
                                playing={false}
                                responsive
                              />
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                              >
                                <Typography
                                  variant="h4"
                                  color="textSecondary"
                                  align="center"
                                >
                                  No Media Available
                                </Typography>
                              </Box>
                            )}
                            {/* We don't want to show "Expand" button if no media is available*/}
                            {mediaVariant?.serving_url && (
                              <div
                                className="media-cover"
                                onClick={() => {
                                  viewMedia(mediaItem.id);
                                }}
                              >
                                <Button
                                  variant="contained"
                                  type="button"
                                  size="small"
                                  onClick={() => {
                                    viewMedia(mediaItem.id);
                                  }}
                                  startIcon={<ExpandIcon />}
                                >
                                  Expand
                                </Button>
                              </div>
                            )}
                          </CardMedia>
                          <CardActions
                            className={classes.cardActions}
                            onClick={(event) => {
                              // Handling click on any element inside.
                              selectMedia(mediaItem.id);
                            }}
                          >
                            <FormControlLabel
                              className={classes.switchLabel}
                              control={
                                <Checkbox
                                  key={
                                    mediaItem.id + selectedMedia[mediaItem.id]
                                  }
                                  value={isMediaSelected(mediaItem.id)}
                                  checked={isMediaSelected(mediaItem.id)}
                                />
                              }
                              label={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  onClick={(event) => {
                                    // Preventing default event behavior and stopping propagation
                                    // so CardActions onClick handler is not invoked.
                                    event.stopPropagation();
                                    event.preventDefault();
                                    selectMedia(mediaItem.id);
                                  }}
                                >
                                  {idx + 1}/{array.length}
                                </Typography>
                              }
                            />
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
              <Box mt={2} display="flex">
                <Box flex="1 auto" />
                <TablePagination
                  component="div"
                  labelRowsPerPage="Items per page"
                  count={totalMediaCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePageSizeChange}
                  page={page}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[25, 50, 100]}
                />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Page>
  );
};

export default Moderation;
