import { useContext, useEffect, useState } from 'react';
import {
  Box,
  colors,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Camera as CameraIcon, Download as DownloadIcon } from 'react-feather';
import { ReactComponent as FacebookIcon } from 'assets/svg/Facebook.svg';
import { ReactComponent as TwitterIcon } from 'assets/svg/Twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/Instagram.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/LinkedIn.svg';
import Page from 'components/Page';
import { getAnalytics } from 'api/keen/Analytics';
import EventContext from 'contexts/event';
import Header from './Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  infoButton: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  infoButtonIcon: {
    color: colors.common.white,
  },
  iconButton: {
    color: colors.common.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
    padding: 7,
    marginRight: 22,
  },
  noWrapLabel: {
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  cardRowHeader: {
    display: 'flex',
    height: 56,
    alignItems: 'center',
    borderBottom: '1px solid #eee',
    whiteSpace: 'nowrap',
  },
  cardRow: {
    display: 'flex',
    height: 70,
    alignItems: 'center',
  },
  cardRowTitle: {
    display: 'inline-flex',
    width: '70%',
    alignItems: 'center',
  },
  cardRowLabel: {
    fontSize: '1em',
    lineHeight: 1,
  },
  cardRowValue: {
    flex: '1 auto',
    paddingLeft: '30px',
  },
  infoAlertBullets: {
    marginLeft: 12,
  },
}));

const Analytics = () => {
  const classes = useStyles();
  const { event } = useContext(EventContext);
  const [keenData, setKeenData] = useState({});

  useEffect(async () => {
    if (!event) {
      return;
    }

    const data = await getAnalytics(event?.collection, event?.end_time);
    if (!data || !data.steps) {
      return;
    }

    const analytics = data.steps.reduce((accum, step, index) => {
      // Makes analytics properties by removing spaces from event_collection and assigning corresponding result value
      accum[step['event_collection'].replace(/ /g, '')] = data.result[index];

      return accum;
    }, {});
    setKeenData(analytics);
  }, [event]);

  return (
    <Page className={classes.root} title="Analytics Overview">
      <Box
        className={classes.contentContainer}
        mx={5}
        my={{ xs: 1, md: 2 }}
        flex="1 auto"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <Box mt={5} display="flex" flex="1">
          <Grid container direction="row" spacing={3} alignContent="flex-start">
            <Grid item md={12} sm={12} xs={12}>
              <Alert severity="info" className={classes.infoAlert}>
                <ul className={classes.infoAlertBullets}>
                  <li>
                    Please ensure ad blockers are disabled as their use may
                    cause data not to show.
                  </li>
                </ul>
              </Alert>
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <Box
                  p={3}
                  mb={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box flex={1} maxWidth="80%" mr="auto" width="0px">
                    <Typography
                      variant="overline"
                      className={classes.noWrapLabel}
                      gutterBottom
                    >
                      Credits Used
                    </Typography>
                    <Typography variant="h3">
                      {event?.credits_spent || 0}
                    </Typography>
                  </Box>
                  <IconButton className={classes.infoButton}>
                    <CameraIcon className={classes.infoButtonIcon} />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <Box
                  p={3}
                  mb={2}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box flex={1} maxWidth="80%" mr="auto" width="0px">
                    <Typography
                      variant="overline"
                      className={classes.noWrapLabel}
                      gutterBottom
                    >
                      Total Downloads
                    </Typography>
                    <Typography variant="h3">
                      {keenData.ImageFileDownloaded}
                    </Typography>
                  </Box>
                  <IconButton className={classes.infoButton}>
                    <DownloadIcon className={classes.infoButtonIcon} />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Paper>
                <Box
                  height="auto"
                  display="flex"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <Box px={3} className={classes.cardRowHeader}>
                    <Typography variant="h5">
                      Total Shares by Platform
                    </Typography>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <IconButton className={classes.iconButton}>
                        <FacebookIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        className={classes.cardRowLabel}
                      >
                        Facebook
                      </Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {keenData.FacebookShareButtonClicked}
                      </Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <IconButton className={classes.iconButton}>
                        <TwitterIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        className={classes.cardRowLabel}
                      >
                        Twitter
                      </Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {keenData.TwitterShareButtonClicked}
                      </Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <IconButton className={classes.iconButton}>
                        <InstagramIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        className={classes.cardRowLabel}
                      >
                        Instagram
                      </Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {keenData.InstagramShareButtonClicked}
                      </Typography>
                    </Box>
                  </Box>
                  <Box px={3} className={classes.cardRow}>
                    <Box className={classes.cardRowTitle}>
                      <IconButton className={classes.iconButton}>
                        <LinkedinIcon />
                      </IconButton>
                      <Typography
                        variant="subtitle2"
                        className={classes.cardRowLabel}
                      >
                        LinkedIn
                      </Typography>
                    </Box>
                    <Box className={classes.cardRowValue}>
                      <Typography color="textSecondary" variant="subtitle2">
                        {keenData.LinkedInShareButtonClicked}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default Analytics;
