import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Box,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
  makeStyles,
  Button,
  TableSortLabel,
} from '@material-ui/core';
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  XCircle as ClearIcon,
} from 'react-feather';
import { KeyboardArrowDown } from '@material-ui/icons';

import EventContext from 'contexts/event';
import { getEvents } from 'api/events';
import tabs from '../eventsTabs';
import Spinner from 'components/Spinner';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1 auto',
    display: 'flex',
  },
  contentBox: {
    width: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  queryField: {
    width: 500,
  },
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f9f9fa !important',
    },
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const SORT_BY_OPTS = ['desc', 'asc'];

const AdminEvents = ({ className, ...rest }) => {
  const classes = useStyles();
  let history = useHistory();
  const [currentTab, setCurrentTab] = useState('all');
  const [page, setPage] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState('');
  const [sortDirection, setSortDirection] = useState(SORT_BY_OPTS[0]);
  const [sort, setSort] = useState('create_time');
  const {
    updateContextValues,
    loading,
    events: eventsData,
  } = useContext(EventContext);
  const { response, data: events = [] } = eventsData;

  useEffect(async () => {
    setTotalEvents(response?.headers['x-total-count'] - 0 || 0);
  }, [response]);

  /*
   * On tab change we fetch events with corresponding state
   */
  useEffect(async () => {
    updateContextValues({ loading: true });
    const { data, response } = await getEvents({
      state: currentTab === 'all' ? null : currentTab,
      sort: sort ? `${sortDirection === 'desc' ? '-' : ''}${sort}` : null,
      q: query || null,
      page: page + 1,
      per_page: limit,
    });
    updateContextValues({ events: { data, response }, loading: false });
  }, [currentTab, page, limit, sortDirection, sort]);

  const searchEvents = async (forcedQuery) => {
    updateContextValues({ loading: true });
    const { data, response } = await getEvents({
      state: currentTab === 'all' ? null : currentTab,
      q: forcedQuery !== undefined ? forcedQuery : query,
    });
    updateContextValues({ events: { data, response }, loading: false });
  };

  const handleTabsChange = (event, value) => {
    setPage(0);
    setQuery('');
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const handleKeyPress = (event) => {
    event.persist();
    if (event.key === 'Enter') {
      searchEvents();
    }
  };

  const createSortHandler = (property) => (event) => {
    setSort(property);
    // We store sort directions in array. Code that determines correct next option in array.
    const prevSortDirIdx = SORT_BY_OPTS.indexOf(sortDirection);
    const nextSortDirIdx = (prevSortDirIdx + 1) % SORT_BY_OPTS.length;

    if (property === sort) {
      setSortDirection(SORT_BY_OPTS[nextSortDirIdx]);
    } else {
      setSortDirection(SORT_BY_OPTS[0]);
    }
  };

  return (
    <Box display="flex" flexDirection="column" flex="1 auto">
      <Card className={clsx(classes.root, className)} {...rest}>
        <Box
          className={classes.contentBox}
          display="flex"
          flexDirection="column"
          flex="1 auto"
        >
          <Box display="flex">
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="secondary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ))}
            </Tabs>
            {loading && <Spinner style={{ justifyContent: 'flex-end' }} />}
          </Box>
          <Divider />
          <Box p={2} minHeight={56} display="flex" alignItems="center">
            <TextField
              size="small"
              className={classes.queryField}
              InputProps={{
                endAdornment: !!query ? (
                  <InputAdornment position="start">
                    <SvgIcon
                      fontSize="small"
                      color="action"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setQuery('');
                        searchEvents(null);
                      }}
                    >
                      <ClearIcon />
                    </SvgIcon>
                  </InputAdornment>
                ) : undefined,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChange}
              onKeyPress={handleKeyPress}
              placeholder="Search events"
              value={query}
              variant="outlined"
            />
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                searchEvents();
              }}
            >
              SEARCH
            </Button>
            <Box flexGrow={1} />
          </Box>
          <PerfectScrollbar style={{ flex: '1 auto', overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Used/Purchased</TableCell>
                  <TableCell
                    sortDirection={
                      sort === 'start_time' ? sortDirection : false
                    }
                  >
                    <TableSortLabel
                      IconComponent={KeyboardArrowDown}
                      active={sort === 'start_time'}
                      direction={sort === 'start_time' ? sortDirection : 'desc'}
                      onClick={createSortHandler('start_time')}
                    >
                      Event Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      sort === 'create_time' ? sortDirection : false
                    }
                  >
                    <TableSortLabel
                      IconComponent={KeyboardArrowDown}
                      active={sort === 'create_time'}
                      direction={
                        sort === 'create_time' ? sortDirection : 'desc'
                      }
                      onClick={createSortHandler('create_time')}
                    >
                      Created
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">View/Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => {
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      key={event.id}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        history.push(`/events/${event.id}`);
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Typography variant="subtitle2" color="textPrimary">
                            {event.display_name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          width: 200,
                        }}
                      >
                        <Typography variant="body2" color="textPrimary">
                          {`${event.account.display_name}`}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <Typography variant="body2" color="textPrimary">
                          {`${event?.credits_spent || 0}/${
                            event.credits_purchased
                          }`}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 260 }}>
                        <Typography variant="body2" color="textPrimary">
                          {moment(event.start_time).format('MMM D, YYYY')} -{' '}
                          {moment(event.end_time).format('MMM D, YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <Typography variant="body2" color="textPrimary">
                          {moment(event.create_time).format('MMM D, YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ width: 160 }}>
                        <IconButton
                          onClick={(evt) => {
                            evt.stopPropagation();
                            evt.preventDefault();
                            history.push(`/events/${event.id}`);
                          }}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            labelRowsPerPage="Events per page"
            count={totalEvents}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Box>
      </Card>
    </Box>
  );
};

AdminEvents.propTypes = {
  className: PropTypes.string,
  events: PropTypes.array.isRequired,
};

AdminEvents.defaultProps = {
  events: [],
};

export default AdminEvents;
