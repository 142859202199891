import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import Page from 'components/Page';
import Header from './Header';
import { getGallery } from '../../api/gallery';
import EventContext from '../../contexts/event';
import { getCollection } from '../../api/collection';
import { MUST_REVIEW } from '../../consts/moderation-types';
import { getGalleryEmbedCode, isAdminUser, isClientUser } from '../../helpers';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import CopyButton from '../../components/CopyButton';
import HelpButton from '../../components/HelpButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowY: 'auto',
  },
  contentContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  gridItem: {
    height: '400px',
  },
  cardDetails: {
    display: 'flex',
  },
  linkButton: {
    backgroundColor: theme.palette.secondary.main,
  },
  linkButtonIcon: {
    color: colors.common.white,
  },
  card: {
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  cardRow: {
    display: 'flex',
    height: '56px',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
  cardRowTitle: {
    width: '40%',
  },
  cardRowValue: {
    flex: '1 auto',
  },
  verticalDivider: {
    height: '100%',
    width: '1px',
    backgroundColor: '#eee',
  },
  switchLabel: {
    marginLeft: 0,
  },
  galleryTextContainer: {
    height: '100%',
  },
  galleryText: {
    height: '100%',
    flex: '1 auto',
  },
}));

const GalleryOverview = () => {
  const classes = useStyles();
  const [gallery, setGallery] = useState(null);
  const [collection, setCollection] = useState(null);
  const { event } = useContext(EventContext);
  const { oidcUser } = useReactOidc();

  useEffect(async () => {
    if (!event) {
      return;
    }

    const galleryRes = getGallery(event?.galleries[0]);
    const collectionRes = getCollection(event?.collection);

    const { data: galleryData } = await galleryRes;
    const { data: collectionData } = await collectionRes;

    setGallery(galleryData);
    setCollection(collectionData);
  }, [event]);

  return (
    <Page className={classes.root} title="Gallery Overview">
      <Box
        className={classes.contentContainer}
        mx={5}
        my={{ xs: 1, md: 2 }}
        flex="1 auto"
        display="flex"
        flexDirection="column"
      >
        <Header />
        <Box mt={5} display="flex" flex={1}>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <Card className={classes.card}>
                <CardHeader title="Gallery details" />
                <Divider />
                <CardContent className={classes.cardContent}>
                  <Box mb={4}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              disabled={isClientUser(oidcUser)}
                              fullWidth
                              key={gallery}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              label="Gallery Heading"
                              value={gallery?.display_name}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled={isClientUser(oidcUser)}
                              key={gallery}
                              fullWidth
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              label="URL"
                              value={gallery?.public_url}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          flex={1}
                          display="flex"
                          className={classes.galleryTextContainer}
                        >
                          <TextField
                            disabled={isClientUser(oidcUser)}
                            key={gallery}
                            className={classes.galleryText}
                            variant="outlined"
                            fullWidth
                            multiline
                            InputProps={{
                              readOnly: true,
                              style: {
                                height: '100%',
                              },
                            }}
                            rows={4}
                            label="Gallery Text"
                            value={gallery?.message}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  {isAdminUser(oidcUser) && (
                    <>
                      <Box display="flex" paddingY={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                              >
                                Password Protection
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                gutterBottom
                              >
                                Require a password to view the gallery.
                              </Typography>
                              <FormControlLabel
                                className={classes.switchLabel}
                                label={
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {!!gallery?.password
                                      ? 'Enabled'
                                      : 'Disabled'}
                                  </Typography>
                                }
                                control={
                                  <Switch
                                    size="small"
                                    checked={!!gallery?.password}
                                    edge="start"
                                    disabled
                                    name="isPublic"
                                  />
                                }
                              />
                            </Box>
                          </Grid>
                          <Grid item sm={6} xs={12}>
                            <TextField
                              fullWidth
                              disabled
                              key={gallery}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              label="Password"
                              value={gallery?.password}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </>
                  )}
                  <Box display="flex" paddingY={3}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            gutterBottom
                          >
                            Manual Approval
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                          >
                            When enabled, only approved media will appear in the
                            gallery.
                          </Typography>
                          <FormControlLabel
                            className={classes.switchLabel}
                            label={
                              <Typography variant="body2" color="textSecondary">
                                {collection?.moderation === MUST_REVIEW
                                  ? 'Enabled'
                                  : 'Disabled'}
                              </Typography>
                            }
                            control={
                              <Switch
                                size="small"
                                disabled
                                checked={collection?.moderation === MUST_REVIEW}
                                edge="start"
                                name="isPublic"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="h6"
                            color="textPrimary"
                            gutterBottom
                          >
                            Embed
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            gutterBottom
                          >
                            Allow clients the option to embed their gallery.
                          </Typography>
                          <FormControlLabel
                            className={classes.switchLabel}
                            label={
                              <Typography variant="body2" color="textSecondary">
                                {!!gallery?.embeddable ? 'Enabled' : 'Disabled'}
                              </Typography>
                            }
                            control={
                              <Switch
                                size="small"
                                key={gallery}
                                disabled
                                checked={!!gallery?.embeddable}
                                edge="start"
                                name="embeddable"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12}>
              <Card className={classes.card}>
                <CardHeader title="Gallery Embed Code" />
                <Divider />
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        key={gallery?.public_url}
                        InputProps={{
                          readOnly: true,
                          style: {
                            fontSize: '0.875rem',
                            fontFamily: gallery?.embeddable
                              ? 'monospace'
                              : '"Roboto", "Helvetica", "Arial", sans-serif',
                          },
                        }}
                        variant="outlined"
                        multiline
                        rows={4}
                        label="Code Snippets"
                        value={getGalleryEmbedCode(gallery)}
                        disabled={!gallery?.embeddable}
                      />
                      {gallery?.embeddable && (
                        <Box
                          pt={2}
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          <HelpButton />
                          <CopyButton
                            copyContent={getGalleryEmbedCode(gallery)}
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default GalleryOverview;
