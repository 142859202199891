import React from 'react';
import { Backdrop, Hidden, makeStyles } from '@material-ui/core';
import LightboxDesktop from './LightboxDesktop';
import LightboxMobile from './LightboxMobile';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1302,
    color: '#fff',
    flexDirection: 'row',
  },
}));

const LightBox = (props) => {
  const { mediaId, close } = props;
  const classes = useStyles();

  return (
    <Backdrop open={!!mediaId} className={classes.backdrop} onClick={close}>
      {!!mediaId && (
        <>
          <Hidden xsDown>
            <LightboxDesktop {...props} />
          </Hidden>
          <Hidden smUp>
            <LightboxMobile open={!!mediaId} {...props} />
          </Hidden>
        </>
      )}
    </Backdrop>
  );
};

export default LightBox;
