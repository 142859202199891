import React, {useCallback, useContext, useState} from 'react';
import axios from 'axios';
import EventContext from 'contexts/event';
import {getGallery} from "../../../api/gallery";

const FIREBASE_CREDIT_COUNT_PASSWORD = '6nMjq8ysuW7ezzf7xctpfkKHvKaUCLHX'; // Password to use to check the count
const FIREBASE_CREDIT_COUNT_URI = 'https://counteventdata-c5lnavwcda-uc.a.run.app/';

const EventProvider = (props) => {
  const initialState = useContext(EventContext);
  const [state, setState] = useState(initialState);

  // Populate Event data upon Event details-relevant page load. Optionally fetch Firebase credit count, if Event's
  // Gallery contains an AI capture_url.
  const fetchAndUpdateCreditsSpent = useCallback(async (eventId) => {
    const { data } = await axios.get(`/events/${eventId}`);
    // Also fetch Gallery so that we have access to capture_url, which is needed to determine if event is an AI event
    // for which we want to use the credit count endpoint in Firebase to get the credits_spent value.
    const galleryResponse = await getGallery(data.galleries[0]);
    const capture_url = galleryResponse.data.capture_url;

    // If the event is an AI event
    if (capture_url.includes("onsnapshot.ai")) {
      let boothHostname = (new URL(capture_url).hostname);

      // Fetch AI credit count from Firebase (which is the true count, given the gallery count includes just aggregate
      // number of photos, not accounting for the number of outputs per single credit use.
      const res = await axios.get(
        `${FIREBASE_CREDIT_COUNT_URI}?pw=${FIREBASE_CREDIT_COUNT_PASSWORD}&boothHostname=${boothHostname}`
      );
      data.credits_spent = res.data.count;
    }

    setState(prevState => ({ ...prevState, event: data }));
  }, []);

  return (
    <EventContext.Provider
      value={{
        ...state,
        fetchAndUpdateCreditsSpent,
        updateContextValues: (valuesToUpdate, callback) => {
          setState(prevState => ({ ...prevState, ...valuesToUpdate }));

          if (typeof callback === 'function') {
            callback();
          }
        },
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventProvider;
