import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const DeleteDialog = ({ open, close, handleDelete, selectedMediaCount }) => {
  const classes = useStyles();

  const onDelete = () => {
    handleDelete();
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle disableTypography={true} className={classes.title}>
        <Typography variant="h3">Are you sure</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          align="center"
          gutterBottom
        >
          Do you really want to permanently delete {selectedMediaCount} photos?
          You can not undo this action.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.title}>
        <Button onClick={onDelete} variant="contained" color="secondary">
          Yes, Delete forever
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
