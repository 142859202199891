import {
  Box,
  Button,
  colors,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { EventOutlined } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonIcon: {
    color: colors.common.white,
  },
  dateLabel: {
    marginTop: `-${theme.spacing(3)}px`,
    marginBottom: theme.spacing(1),
    lineHeight: '100%',
  },
}));

const EventEntry = ({ event }) => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={4} display="flex" alignItems="center">
        <IconButton className={classes.button}>
          <EventOutlined className={classes.buttonIcon} />
        </IconButton>
        <Box flex={1} display="flex" flexDirection="column">
          <Typography variant="overline" className={classes.dateLabel}>
            {moment(event?.start_time).format('MMM D, YYYY')} -{' '}
            {moment(event?.end_time).format('MMM D, YYYY')}
          </Typography>
          <Typography variant="h4">{event?.display_name}</Typography>
        </Box>
        <Button
          variant="contained"
          component={Link}
          to={`/events/${event?.id}`}
        >
          EVENT DETAILS
        </Button>
      </Box>
    </Paper>
  );
};

export default EventEntry;
