import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'initial',
  },
}));

const AnalyticsCard = () => {
  const classes = useStyles();

  return (
    <Paper>
      <Box p={3}>
        <Typography variant="h1">Learn More</Typography>
        <Typography style={{ marginTop: 20 }} gutterBottom>
          Snapbar is regularly adding products and features. Click below to learn the latest about each product:
        </Typography>
        <Typography>
          <b><a href="https://snapbar.com/snapshot#updates" className={classes.link}>Snapshot</a>:</b> the
          customizable <a href="https://snapbar.com/snapshot#updates"
          className={classes.link}>online photo booth platform</a>
        </Typography>
        <Typography>
          <b><a href="https://snapbar.com/studio" className={classes.link}>Studio</a>:</b> the platform for <a
          href="https://snapbar.com/studio" className={classes.link}>professional business headshots</a>
        </Typography>
      </Box>
    </Paper>
  );
}

export default AnalyticsCard;
