import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ArrowRight as ArrowRightIcon } from 'react-feather';
import React, { useEffect, useState } from 'react';
import { getEventLabel } from '../../helpers';
import moment from 'moment';
import NoEventCard from '../NoEventCard';
import { Link } from 'react-router-dom';
import { getGallery } from '../../api/gallery';

const useStyles = makeStyles((theme) => ({
  allEventsButton: {
    textAlign: 'right',
  },
  arrowRightIcon: {
    fontSize: '135%',
    marginLeft: 7,
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  externalLink: {
    textDecoration: 'none',
  },
  quickLinkBox: {
    marginBottom: theme.spacing(2),
  },
  quickLinkButtonSpacing: {
    marginRight: theme.spacing(2),
  },
}));

const EventCard = ({ event, withLabel }) => {
  const classes = useStyles();

  const [galleryURL, setGalleryURL] = useState(null);
  const [boothURL, setBoothURL] = useState(null);

  useEffect(async () => {
    if (!event) {
      return;
    }

    const gallertyReq = getGallery(event.galleries[0]);

    const { data: galleryData } = await gallertyReq;

    setGalleryURL(galleryData.public_url);
    setBoothURL(galleryData.capture_url);
  }, [event]);

  if (!event && withLabel) {
    return <NoEventCard />;
  }

  return (
    <Paper>
      <Box p={3}>
        <Grid container direction="row" alignItems="flex-start">
          {withLabel && (
            <Grid item xs={6}>
              <Typography
                variant="overline"
                align="left"
                display="block"
                gutterBottom
              >
                {getEventLabel(event)}
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography
              variant="overline"
              align={withLabel ? 'right' : 'left'}
              display="block"
              gutterBottom
            >
              {moment(event?.start_time).format('MMM D, YYYY')} -{' '}
              {moment(event?.end_time).format('MMM D, YYYY')}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h1">{event?.display_name}</Typography>
        <Divider className={classes.divider} />
        <Typography variant="overline" gutterBottom>
          QUICK LINKS
        </Typography>
        <Box className={classes.quickLinkBox}>
          <Button
            variant="contained"
            component={Link}
            to={`/events/${event?.id}`}
            className={classes.quickLinkButtonSpacing}
          >
            EVENT DETAILS
          </Button>
          <Button
            variant="contained"
            component={Link}
            to={`/events/${event?.id}/moderation`}
          >
            MODERATION
          </Button>
        </Box>
        <Typography variant="overline" gutterBottom>
          YOUR EVENT
        </Typography>
        <Box>
          <a
            href={galleryURL}
            target="_blank"
            rel="noreferrer"
            className={classes.externalLink}
          >
            <Button
              variant="contained"
              className={classes.quickLinkButtonSpacing}
            >
              GALLERY
            </Button>
          </a>

          <a
            href={boothURL}
            target="_blank"
            rel="noreferrer"
            className={classes.externalLink}
          >
            <Button variant="contained">BOOTH</Button>
          </a>
        </Box>
        {withLabel && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.allEventsButton}>
              <Button variant="text" component={Link} to={`/events`}>
                SEE ALL EVENTS
                <SvgIcon fontSize="small" className={classes.arrowRightIcon}>
                  <ArrowRightIcon />
                </SvgIcon>
              </Button>
            </div>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default EventCard;
