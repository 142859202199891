import React from 'react';
import { Box, Typography } from '@material-ui/core';

const Copyright = () => {
  /* Calculate the current year to include in the footer, so that it's always up-to-date. */
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <Box p={2}>
      <Typography variant="caption" color="textSecondary">
        Snapbar &copy; {getCurrentYear()}
      </Typography>
    </Box>
  );
};

export default Copyright;
